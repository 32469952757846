import React from "react";
import { _ACTIONS, _COLORS } from "../../utils/_constants";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "@mui/material/Button";
import { btn } from "../../utils/_style_constants";
import { Grid } from "@mui/material";
import { useState } from "react";

export default function ActualiteForm({
  requestHelper,
  content,
  setContent,
  setShowModal,
}) {
  const [infoHtml, setInfoHtml] = useState(content);

  const handleSubmit = (e) => {
    e.preventDefault();
    // handle form submission here
    requestHelper
      .doRequest(_ACTIONS.POST, "/api/actualites", {
        content: infoHtml,
      })
      .then((r) => {
        if (r.status === 201) {
          setContent(r.data.content);
          setShowModal(false);
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CKEditor
          editor={ClassicEditor}
          label="Actualités sur la page d'accueil"
          data={infoHtml ? infoHtml : content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setInfoHtml(data);
          }}
        />
        <Grid sx={{ margin: "10px" }} container justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            size="large"
            style={btn(_COLORS.primary, _COLORS.light, "50%")}
          >
            Enregistrer les modifications
          </Button>
        </Grid>
      </form>
    </>
  );
}
