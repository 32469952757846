import React, { useRef, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { _COLORS } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import _, { isUndefined } from "lodash";
import { createConfirm } from "../../../utils/confirm";

function CreationMandatVente({
  register,
  errors,
  typeVentes,
  currentMandat,
  setCurrentMandat,
  mode = "vente",
}) {
  return (
    <>
      <Grid container spacing={1} padding={2}>
        {currentMandat.type != "avenant" && (
          <>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  color: _COLORS.primary,
                  paddingBottom: 20,
                }}
              >
                Modification du mandat de {mode}
              </Typography>
            </Grid>
          </>
        )}

        {currentMandat.type != "recherche" ? (
          <>
            <Grid
              item
              xs={12}
              md={6}
              alignItems={"center"}
              display={"flex"}
              gap={3}
            >
              <Grid item xs={4}>
                <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
                  Type de vente*
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl sx={{ display: "flex" }} fullWidth>
                  <InputLabel id="typeVenteLabel" size="small">
                    Type de vente
                  </InputLabel>
                  <Select
                    label="Type de vente"
                    size="small"
                    disabled={currentMandat.type === "avenant"}
                    value={
                      currentMandat?.typeVente
                        ? currentMandat.typeVente["@id"]
                        : ""
                    }
                    {...register("typeVente", { required: false })}
                    id="typeVente"
                    onChange={(e) => {
                      if (!_.isNull(currentMandat?.typeVente)) {
                        createConfirm(
                          "Êtes-vous sûr de vouloir modifier le type de vente ? (Les ventilations de prix déjà existantes seronts supprimées)"
                        )
                          .fire({})
                          .then((result) => {
                            if (result.isConfirmed) {
                              // Traitement
                              let m = editInstantRequest(
                                e.target.value,
                                currentMandat["@id"],
                                "typeVente"
                              );

                              m.then((m) => {
                                setCurrentMandat(m);
                              });
                            } else {
                              // Annulation
                              setCurrentMandat(currentMandat);
                            }
                          });
                      } else {
                        let m = editInstantRequest(
                          e.target.value,
                          currentMandat["@id"],
                          "typeVente"
                        );

                        m.then((m) => {
                          setCurrentMandat(m);
                        });
                      }
                    }}
                  >
                    {typeVentes.map((typeVente, i) => (
                      <MenuItem key={i} value={typeVente["@id"]}>
                        {typeVente.nom}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.typeVente?.type === "required" && (
                    <Typography sx={_STYLES.errorMessage}>
                      Veuillez selectionner un type de vente
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}

        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          gap={3}
          alignItems={"center"}
        >
          <Grid xs={4} item>
            <Typography
              variant="body1"
              sx={{ ..._STYLES.typographyLabelTitle }}
            >
              Exclusivité*
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <InputLabel size="small">Exclusivité</InputLabel>
              <Select
                label="Exclusivité"
                disabled={currentMandat.type === "avenant"}
                size="small"
                {...register("exclusif", { required: false })}
                id="exclusif"
                value={
                  !isUndefined(currentMandat?.exclusif)
                    ? currentMandat.exclusif
                    : ""
                }
                onChange={(e) => {
                  let m = editInstantRequest(
                    e.target.value,
                    currentMandat["@id"],
                    "exclusif"
                  );

                  m.then((m) => {
                    setCurrentMandat(m);
                  });
                }}
              >
                <MenuItem value={true}>Exclusif</MenuItem>
                <MenuItem value={false}>Non exclusif</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          alignItems={"center"}
          display={"flex"}
          gap={3}
        >
          <Grid item xs={4}>
            <Typography
              variant="body1"
              sx={{ ..._STYLES.typographyLabelTitle }}
            >
              Lieu de vente*
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <InputLabel size="small">Lieu de vente</InputLabel>
              <Select
                label="Lieu de la vente"
                size="small"
                disabled={currentMandat.type === "avenant"}
                {...register("lieuDeVente", { required: false })}
                id="lieuDeVente"
                value={
                  currentMandat?.lieuDeVente ? currentMandat.lieuDeVente : ""
                } // Utilisez la variable d'état pour définir la valeur sélectionnée
                onChange={(e) => {
                  let m = editInstantRequest(
                    e.target.value,
                    currentMandat["@id"],
                    "lieuDeVente"
                  );

                  m.then((m) => {
                    setCurrentMandat(m);
                  });
                  // Mettez à jour la variable d'état lorsqu'une nouvelle option est sélectionnée
                }}
              >
                <MenuItem key={1} value="horsEtablissement">
                  Hors établissement avec faculté de rétraction
                </MenuItem>
                <MenuItem key={2} value="auSeinEtablissement">
                  Au sein de l'établissement
                </MenuItem>
              </Select>
              {errors.lieuDeVente?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  Veuillez selectionner un lieu de vente
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreationMandatVente;
