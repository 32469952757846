import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { getCookie, setCookie } from "../../utils/cookieHelper";
import _ from "lodash";

export const UserContext = createContext({});

/**
 * @param {*} param0
 * @returns <UserContext.Provider/>
 * @returns <UserContext.Consumer/>
 *
 */
function UserContextProvider({ children }) {
  const [user, setUser] = React.useState({});
  const [role, setRole] = React.useState({});
  const [profil, setProfil] = React.useState({});
  const [token, setToken] = React.useState("");

  const users = {
    user,
    updateUser(newUser) {
      setUser(newUser);
    },
    token,
    updateToken(newToken) {
      setToken(newToken);
    },
    role,
    updateRole(newRole) {
      setRole(newRole);
    },
    profil,
    updateRole(newRole) {
      setProfil(newRole);
    },
  };

  useEffect(() => {
    // On va chercher les données de l'utilisateur dans le sessionStorage
    if (_.isEmpty(user)) {
      if (!_.isEmpty(window.sessionStorage.getItem("user"))) {
        setUser(JSON.parse(window.sessionStorage.getItem("user")));
      }
    }
    // Si le user est renseigné dans le contexte, on le stocke dans le sessionStorage
    if (
      !_.isEmpty(user) &&
      window.sessionStorage.getItem("user") !== JSON.stringify(user)
    ) {
      window.sessionStorage.setItem("user", JSON.stringify(user));
      setRole(user.roles);
      window.sessionStorage.setItem("user", JSON.stringify(user));
      setProfil(user.profil);
    }

    // On va chercher le token dans les cookies
    if (token === "") {
      if (getCookie("token")) {
        setToken(getCookie("token"));
      }
    }
    if (token !== "" && getCookie("token") !== token) {
      setCookie("token", token, 1);
    }
  }, []);

  return <UserContext.Provider value={users}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
