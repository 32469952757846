import {
  Box,
  Button,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _ACTIONS, _COLORS } from "../../../utils/_constants";
import { DatePicker } from "@mui/x-date-pickers";
import { _STYLES, btn } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import moment from "moment";
import { createToast } from "../../../utils/toast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import _ from "lodash";
import { PatternFormat } from "react-number-format";

function Mandant({
  currentMandat,
  setCurrentMandat,
  refresh,
  typeMandants,
  register,
  errors,
  civilites,
  requestHelper,
}) {
  let modeHumain = false;
  let modeSociete = false;
  const [modeTutelle, setModeTutelle] = useState(false);
  const [boutonMandant, setBoutonMandant] = useState(false);

  useEffect(() => {
    if (currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/4") {
      setModeTutelle(true);
    }
    if (
      currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/3" ||
      currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/4"
    ) {
      setBoutonMandant(true);
    }
  }, [currentMandat]);

  const copyAddress = () => {
    let mandant = currentMandat.mandants[0];
    let nextMandant = currentMandat.mandants[1];

    requestHelper
      .doRequest(_ACTIONS.PUT, nextMandant["@id"], {
        adresse: mandant.adresse,
        portable: mandant.portable,
        email: mandant.email,
      })
      .then((data) => {
        refresh();
      });
  };

  const handleChange = (value, f, mandant) => {
    setCurrentMandat({
      ...currentMandat,
      mandants: currentMandat.mandants.map((m) => {
        if (m["@id"] === mandant["@id"]) {
          m[f] = value;
        }
        return m;
      }),
    });
  };

  return (
    <>
      <Grid container columnSpacing={1} rowSpacing={1} padding={4}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Mandants
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems={"center"} display={"flex"}>
          <Grid item xs={4} md={4}>
            <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
              Type de mandant*
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControl fullWidth>
              <InputLabel size="small"> Type de mandant</InputLabel>
              <Select
                label="Type de mandant"
                size="small"
                disabled={currentMandat?.type === "avenant"}
                {...register("typeMandant", { required: false })}
                id="typeMandant"
                value={
                  currentMandat?.typeMandant
                    ? currentMandat.typeMandant["@id"]
                    : ""
                }
                onChange={(e) => {
                  setModeTutelle(e.target.value === "/api/type_mandants/4");
                  setBoutonMandant(
                    e.target.value === "/api/type_mandants/3" ||
                      e.target.value === "/api/type_mandants/4"
                  );

                  let m = editInstantRequest(
                    e.target.value,
                    "/api/mandats/" + currentMandat.id,
                    "typeMandant"
                  );

                  m.then((m) => {
                    setCurrentMandat(m);
                  });
                  //@TODO function qui permet de rafraichier tout les inputs
                }}
              >
                {typeMandants?.map((typeMandant, i) => (
                  <MenuItem key={i} value={typeMandant["@id"]}>
                    {typeMandant.nom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {currentMandat?.mandants?.map(
          (mandant, i) => (
            (modeHumain = mandant?.civilite?.id != "3"),
            (modeSociete = mandant?.civilite?.id == "3"),
            (
              <Grid container spacing={1} key={i} sx={{ paddingTop: "1em" }}>
                <Grid
                  item
                  md={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      ..._STYLES.typographyLabelTitle,
                      ...{ paddingRight: "1em" },
                    }}
                  >
                    {i + 1}
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel size="small">Civilité</InputLabel>
                    <Select
                      label="Civilité"
                      size="small"
                      disabled={currentMandat?.type === "avenant"}
                      value={mandant.civilite ? mandant.civilite["@id"] : ""}
                      {...register(i + "civilite", { required: false })}
                      id={i + "civilite"}
                      onChange={(e) => {
                        editInstantRequest(
                          e.target.value,
                          mandant["@id"],
                          "civilite"
                        );

                        refresh();
                      }}
                    >
                      {civilites?.map((civilite, i) => (
                        <MenuItem key={i} value={civilite["@id"]}>
                          {civilite.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Mode Personne */}
                {!modeSociete && modeHumain && (
                  <>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id={i + "nom"}
                        label="Nom"
                        disabled={currentMandat?.type === "avenant"}
                        value={mandant.nom !== null ? mandant.nom : ""}
                        // Besoin de changer la valeur du state pour que le composant se rafraichisse
                        onChange={(e) => {
                          handleChange(e.target.value, "nom", mandant);
                        }}
                        // On envoie la valeur au serveur
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "nom"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id={i + "prenom"}
                        label="Prénom"
                        disabled={currentMandat?.type === "avenant"}
                        value={mandant.prenom !== null ? mandant.prenom : ""}
                        // Besoin de changer la valeur du state pour que le composant se rafraichisse
                        onChange={(e) => {
                          handleChange(e.target.value, "prenom", mandant);
                        }}
                        // On envoie la valeur au serveur
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "prenom"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <DatePicker
                        label="Date de naissance"
                        size="small"
                        disabled={currentMandat?.type === "avenant"}
                        value={
                          mandant.dateNaissance
                            ? moment(mandant.dateNaissance)
                            : null
                        }
                        format="DD/MM/YYYY"
                        onChange={(date) => {
                          let dateToSend = date?.toLocaleString("fr-FR", {
                            timeZone: "Europe/paris",
                          });
                          if (dateToSend !== "Invalid date") {
                            editInstantRequest(
                              _.isNull(dateToSend) ? null : dateToSend,
                              mandant["@id"],
                              "dateNaissance"
                            );
                            refresh();
                          }
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <TextField
                        fullWidth
                        size="small"
                        disabled={currentMandat?.type === "avenant"}
                        id={i + "lieuNaissance"}
                        label="Lieu de naissance"
                        value={
                          mandant.lieuNaissance !== null
                            ? mandant.lieuNaissance
                            : ""
                        }
                        // Besoin de changer la valeur du state pour que le composant se rafraichisse
                        onChange={(e) => {
                          handleChange(
                            e.target.value,
                            "lieuNaissance",
                            mandant
                          );
                        }}
                        // On envoie la valeur au serveur
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "lieuNaissance"
                          );

                          refresh();

                          console.log(currentMandat);
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* Mode Société */}
                {modeSociete && !modeHumain && (
                  <>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="nomSociete"
                        label="Nom de la société"
                        disabled={currentMandat?.type === "avenant"}
                        value={
                          mandant.nomSociete !== null ? mandant.nomSociete : ""
                        }
                        handleChange={(e) => {
                          handleChange(e.target.value, "nomSociete", mandant);
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "nomSociete"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="capital"
                        label="Capital social"
                        disabled={currentMandat?.type === "avenant"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                        value={mandant.capital ? mandant.capital : ""}
                        onChange={(e) => {
                          handleChange(e.target.value, "capital", mandant);
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "capital"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="rcs"
                        label="Rcs"
                        disabled={currentMandat?.type === "avenant"}
                        value={mandant.rcs !== null ? mandant.rcs : ""}
                        handleChange={(e) => {
                          handleChange(e.target.value, "rcs", mandant);
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "rcs"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="rcsVille"
                        label="Rcs Ville"
                        disabled={currentMandat?.type === "avenant"}
                        value={
                          mandant.rcsVille !== null ? mandant.rcsVille : ""
                        }
                        handleChange={(e) => {
                          handleChange(e.target.value, "rcsVille", mandant);
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "rcsVille"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="rcsRepresentant"
                        label="Rcs Représentant"
                        disabled={currentMandat?.type === "avenant"}
                        value={
                          mandant.rcsRepresentant !== ""
                            ? mandant.rcsRepresentant
                            : ""
                        }
                        handleChange={(e) => {
                          handleChange(
                            e.target.value,
                            "rcsRepresentant",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "rcsRepresentant"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* Fin du mode Société */}
                {/* Partie commune */}
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "space-between",
                  }}
                  item
                  md={3}
                >
                  <TextField
                    fullWidth
                    size="small"
                    disabled={currentMandat?.type === "avenant"}
                    id={i + "adresse"}
                    label="Adresse"
                    value={mandant.adresse !== null ? mandant.adresse : ""}
                    // Besoin de changer la valeur du state pour que le composant se rafraichisse
                    onChange={(e) => {
                      handleChange(e.target.value, "adresse", mandant);
                    }}
                    // On envoie la valeur au serveur
                    onBlur={(e) => {
                      editInstantRequest(
                        e.target.value,
                        mandant["@id"],
                        "adresse"
                      );

                      refresh();
                    }}
                  />
                </Grid>
                <Grid item md={1}>
                  <PatternFormat
                    format="##.##.##.##.##"
                    customInput={TextField}
                    fullWidth
                    size="small"
                    disabled={currentMandat?.type === "avenant"}
                    id={i + "portable"}
                    name={i + "portable"}
                    label="Tel portable"
                    value={mandant.portable !== null ? mandant.portable : ""}
                    {...register(i + "portable", {
                      onChange: (e) => {
                        let stringInput = e.target.value;
                        e.target.value = stringInput.replace(/[^0-9]/gi, "");
                        handleChange(e.target.value, "portable", mandant);
                      },
                      onBlur: (e) => {
                        if (errors[i + "portable"]) {
                          createToast(
                            "error",
                            "Il y a une erreur sur ce champ la valeur n'est pas mise à jour"
                          );

                          refresh();
                        } else {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "portable"
                          );

                          refresh();
                        }
                      },
                      maxLength: {
                        value: 10,
                        message: "error message",
                      },
                      minLength: {
                        value: 10,
                        message: "error message",
                      },
                    })}
                  />
                </Grid>
                <Grid item md={1}>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={currentMandat?.type === "avenant"}
                    id={i + "email"}
                    name={i + "email"}
                    label="Email"
                    value={mandant.email !== null ? mandant.email : ""}
                    {...register(i + "email", {
                      onChange: (e) => {
                        handleChange(e.target.value, "email", mandant);
                      },
                      onBlur: (e) => {
                        if (errors[i + "email"]) {
                          createToast(
                            "error",
                            "Il y a une erreur sur ce champ la valeur n'est pas mise à jour"
                          );

                          refresh();
                        } else {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "email"
                          );

                          refresh();
                        }
                      },
                      validate: {
                        isEmail: (value) => {
                          return (
                            value === "" ||
                            (value.includes("@") && value.includes("."))
                          );
                        },
                      },
                    })}
                  />
                </Grid>

                {/* Bouton Copier l'adresse */}
                <Grid
                  item
                  md={0.5}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {currentMandat.typeMandant["@id"] ===
                    "/api/type_mandants/2" &&
                    i == 0 && (
                      <ArrowDownwardIcon
                        title="Copier les informations du conjoint"
                        sx={{ cursor: "pointer", marginLeft: "1em" }}
                        onClick={copyAddress}
                      ></ArrowDownwardIcon>
                    )}
                </Grid>
                {/** Bouton Supprimer */}
                <Grid
                  item
                  md={0.5}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {(currentMandat.typeMandant["@id"] ===
                    "/api/type_mandants/3" ||
                    currentMandat.typeMandant["@id"] ===
                      "/api/type_mandants/4") &&
                    i > 0 && (
                      <HighlightOffIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          requestHelper
                            .doRequest(_ACTIONS.DELETE, mandant["@id"])
                            .then((data) => {
                              refresh();
                            });
                        }}
                      ></HighlightOffIcon>
                    )}
                </Grid>

                {/* Mode Tutelle */}
                {modeTutelle && (
                  <>
                    <Grid item md={2}></Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="nomTuteur"
                        disabled={currentMandat?.type === "avenant"}
                        label="Nom du Tuteur"
                        value={
                          mandant.nomTuteur !== null ? mandant.nomTuteur : ""
                        }
                        handleChange={(e) => {
                          handleChange(e.target.value, "nomTuteur", mandant);
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "nomTuteur"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="decisionTuteur"
                        disabled={currentMandat?.type === "avenant"}
                        label="Décision du Tuteur"
                        value={
                          mandant.decisionTuteur !== null
                            ? mandant.decisionTuteur
                            : ""
                        }
                        handleChange={(e) => {
                          handleChange(
                            e.target.value,
                            "decisionTuteur",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "decisionTuteur"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="tribunalTuteur"
                        disabled={currentMandat?.type === "avenant"}
                        label="Nom du Tuteur"
                        value={
                          mandant.tribunalTuteur !== null
                            ? mandant.tribunalTuteur
                            : ""
                        }
                        handleChange={(e) => {
                          handleChange(
                            e.target.value,
                            "tribunalTuteur",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "tribunalTuteur"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <DatePicker
                        label="Date decision tribunal"
                        size="small"
                        disabled={currentMandat?.type === "avenant"}
                        value={
                          mandant.dateTribunalTuteur
                            ? mandant.dateTribunalTuteur
                            : null
                        }
                        format="DD/MM/YYYY"
                        onChange={(date) => {
                          editInstantRequest(
                            date,
                            mandant["@id"],
                            "dateTribunalTuteur"
                          );

                          refresh();
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid container>
                  {errors && errors[i + "portable"] && (
                    <Typography variant="caption" color="red">
                      Le numéro de téléphone doit contenir 10 chiffres
                    </Typography>
                  )}
                  {errors && errors[i + "email"] && (
                    <Typography variant="caption" color="red">
                      Le format de l'email n'est pas bon
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )
          )
        )}

        {/* Bouton Ajouter un mandant */}
        {boutonMandant && (
          <Grid container>
            <Grid item md={10}></Grid>
            <Grid item md={2}>
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Button
                  sx={{
                    ...btn(_COLORS.primary, _COLORS.primaryLight),
                  }}
                  onClick={() => {
                    requestHelper
                      .doRequest(_ACTIONS.POST, "/api/add-new-mandant", {
                        mandat: currentMandat.id,
                      })
                      .then((data) => {
                        refresh();
                      });
                  }}
                >
                  <AddCircleOutlineIcon></AddCircleOutlineIcon>
                  Ajouter un mandant
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Mandant;
