import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { _COLORS } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function ConditionsParticulieres({ currentMandat, setCurrentMandat, refresh }) {
  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 4 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Conditions particulières
            </Typography>
          </Grid>
          {currentMandat?.type != "recherche" ? (
            <>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          currentMandat?.consentementConjointVendeur
                            ? true
                            : false
                        }
                        disabled={currentMandat?.type === "avenant"}
                        onChange={(e) => {
                          let m = editInstantRequest(
                            e.target.checked,
                            currentMandat["@id"],
                            "consentementConjointVendeur"
                          );

                          m.then((m) => {
                            if (m["@context"] === "/api/contexts/Error") {
                              refresh();
                            } else {
                              setCurrentMandat(m);
                            }
                          });
                        }}
                      />
                    }
                    label="Consentement du conjoint du vendeur."
                    name="consentementConjointVendeur"
                    size="small"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={8}>
                <FormControl>
                  <InputLabel size="small" id="consentementVendeurLabel">
                    Choix du vendeur :
                  </InputLabel>
                  <Select
                    label="Choix du vendeur :"
                    sx={{ width: "25em" }}
                    size="small"
                    disabled={currentMandat?.type === "avenant"}
                    value={
                      currentMandat?.consentementVendeur
                        ? currentMandat?.consentementVendeur["@id"]
                        : ""
                    }
                    id="consentementVendeur"
                    onChange={(e) => {
                      let m = editInstantRequest(
                        e.target.value,
                        currentMandat["@id"],
                        "consentementVendeur"
                      );

                      m.then((m) => {
                        if (m["@context"] === "/api/contexts/Error") {
                          refresh();
                        } else {
                          setCurrentMandat(m);
                        }
                      });
                    }}
                  >
                    {currentMandat?.mandants?.map((mandant, i) => {
                      return (
                        <MenuItem key={i} value={mandant["@id"]}>
                          {mandant.nom} {mandant.prenom}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        currentMandat?.clauseDepartAnticipe ? true : false
                      }
                      disabled={currentMandat?.type === "avenant"}
                      onChange={(e) => {
                        let m = editInstantRequest(
                          e.target.checked,
                          currentMandat["@id"],
                          "clauseDepartAnticipe"
                        );

                        m.then((m) => {
                          if (m["@context"] === "/api/contexts/Error") {
                            refresh();
                          } else {
                            setCurrentMandat(m);
                          }
                        });
                      }}
                    />
                  }
                  label="Clause départ anticipé. Montant de la mensualité à verser par l'acquéreur au profit du vendeur*"
                  name="clauseDepartAnticipe"
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={8}
                sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
              >
                <TextField
                  size="small"
                  type="number"
                  disabled={currentMandat?.type === "avenant"}
                  sx={{ width: "25em" }}
                  label="Votre montant :"
                  value={
                    currentMandat?.clauseDepartAnticipeMontant
                      ? currentMandat?.clauseDepartAnticipeMontant
                      : ""
                  }
                  onChange={(e) => {
                    setCurrentMandat({
                      ...currentMandat,
                      clauseDepartAnticipeMontant: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    let m = editInstantRequest(
                      parseFloat(e.target.value),
                      currentMandat["@id"],
                      "clauseDepartAnticipeMontant"
                    );

                    m.then((m) => {
                      if (m["@context"] === "/api/contexts/Error") {
                        refresh();
                      } else {
                        setCurrentMandat(m);
                      }
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <Box sx={{ display: "flex" }}>
          <Grid item xs={4}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentMandat?.autreConditions ? true : false}
                    disabled={currentMandat?.type === "avenant"}
                    onChange={(e) => {
                      let m = editInstantRequest(
                        e.target.checked,
                        currentMandat["@id"],
                        "autreConditions"
                      );

                      m.then((m) => {
                        if (m["@context"] === "/api/contexts/Error") {
                          refresh();
                        } else {
                          setCurrentMandat(m);
                        }
                      });
                    }}
                  />
                }
                label="Autres clauses :"
                name="autreCondtions"
                size="small"
              />
            </FormGroup>
          </Grid>
          <Grid paddingTop={"2em"} item xs={8}>
            <CKEditor
              editor={ClassicEditor}
              label="Autre clauses"
              disabled={currentMandat?.type === "avenant"}
              data={
                currentMandat?.autreConditionsText
                  ? currentMandat?.autreConditionsText
                  : ""
              }
              onBlur={(event, editor) => {
                const data = editor.getData();
                let m = editInstantRequest(
                  data,
                  currentMandat["@id"],
                  "autreConditionsText"
                );

                m.then((m) => {
                  if (m["@context"] === "/api/contexts/Error") {
                    refresh();
                  } else {
                    setCurrentMandat(m);
                  }
                });
              }}
            />
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default ConditionsParticulieres;
