import { Box, TextField, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useContext, useState } from "react";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { RequestHelper } from "../../utils/requestHelper";
import BasicModal from "../helpers/BasicModal";
import { AgenceForm } from "../forms/AgenceForm";
import { _COLORS, _ACTIONS, _MESSAGES } from "../../utils/_constants";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { _STYLES, btn } from "../../utils/_style_constants";
import { UserContext } from "../contexts/UserContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import LifeConsentApiForm from "../forms/LifeConsentApiForm";
import { createConfirm } from "../../utils/confirm";

/**
 * This component displays a list of agencies and allows the user to search, add, and edit agencies.
 * It uses a DataGrid component from the Material-UI library to display the list of agencies.
 * The list of agencies is retrieved from an API using a GET request.
 * The component also includes a modal for adding/editing agencies.
 * @component
 * @returns {JSX.Element} The ListAgences component.
 */
function ListAgences() {
  const [rowsAgence, setRowsAgence] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalApi, setShowModalApi] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const { token, user } = useContext(UserContext);
  const [agence, setAgence] = useState(null);
  const requestHelper = new RequestHelper(token);

  useEffect(() => {
    refreshListAgence();
  }, [refresh]);

  const columns = [
    {
      field: "ville",
      headerName: "Ville",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "raisonSociale",
      headerName: "Raison Sociale",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "nom",
      headerName: "Agence",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "email",
      headerName: "Mail",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "tel",
      headerName: "Téléphone",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "adresse",
      headerName: "Adresse",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => (
        <strong>
          {params.row.adresse} {params.row.codePostal}
        </strong>
      ),
    },
    {
      field: "carteT",
      headerName: "Carte T",
      flex: 1,
      headerClassName: "primaryLight",
    },
    {
      field: "assureur.nom",
      headerName: "Assureur",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => <strong>{params.row.assureur?.nom}</strong>,
    },
    {
      field: "lifeConsentAvaible",
      headerName: "Api",
      flex: 0.5,
      headerClassName: "primaryLight",
      renderCell: (params) =>
        params.row.lifeConsentAvaible ? (
          <strong>
            <CheckBoxIcon sx={{ color: _COLORS.success }} />
          </strong>
        ) : (
          <strong>
            <CheckBoxOutlineBlankIcon sx={{ color: _COLORS.danger }} />
          </strong>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => (
        <strong>
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => doEdit(params)}
          ></EditIcon>
          {(user.profil === "SADMIN" || user.profil === "ADMIN") && (
            <OpenInBrowserIcon
              sx={{ cursor: "pointer" }}
              onClick={() => doConfigApi(params)}
            ></OpenInBrowserIcon>
          )}
        </strong>
      ),
    },
  ];

  /**
   * Refreshes the list of agencies by making a GET request to the API.
   */
  const refreshListAgence = () => {
    // restriction pour les utilisateurs
    const listeAgenceUser = user?.agences;
    let params = [];
    if (user.profil === "USER" || user.profil === "SUSER") {
      if (listeAgenceUser) {
        params = "/?id[]=" + listeAgenceUser.map((agence) => agence.id);
      }
    }
    requestHelper
      .doRequest(_ACTIONS.GET, "/api/agences" + params, {
        listAgenceSearch: search,
      })
      .then((r) => {
        setRowsAgence(r.data["hydra:member"]);
      });
  };

  /**
   * Handler for showing/hiding the modal for adding/editing an agency.
   */
  function showModalHandler() {
    setAgence(null);
    setShowModal(!showModal);
  }

  /**
   * Sets the agency to be edited and shows the modal for editing.
   * @param {Object} params - The parameters for the row being edited.
   */
  function doEdit(params) {
    setAgence(params.row);
    setShowModal(true);
  }

  const doConfigApi = (params) => {
    if (params.row.lifeConsentAvaible) {
      createConfirm(_MESSAGES.confirm_update_api_lifeconsent)
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            setAgence(params.row);
            setShowModalApi(true);
          } else {
            return;
          }
        });
    } else {
      setAgence(params.row);
      setShowModalApi(true);
    }
  };

  return (
    <Box sx={_STYLES.mainContainer}>
      {/* Filter Form  */}
      <Grid sx={_STYLES.filterFormGrid} rowSpacing={1} container>
        {/** Title */}
        <Grid item xl={3}>
          <Typography
            variant="h5"
            style={{
              padding: "15px",
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Recherche agences
          </Typography>
        </Grid>
        <Grid item xl={9}></Grid>
        <Grid item xl={12}></Grid>
        {/* Filter Form */}
        <Grid item xl={3}>
          <TextField
            sx={{ width: "90%" }}
            value={search}
            size="small"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            label="Raison Sociale, nom de l'agence, responsable, ville"
          ></TextField>
        </Grid>
        <Grid item xl={2}>
          <Button
            sx={btn(_COLORS.primary, _COLORS.primaryLight)}
            variant="contained"
            onClick={refreshListAgence}
          >
            Rechercher
          </Button>
        </Grid>
        <Grid item xl={7}></Grid>
      </Grid>

      {/* Modal Agence Form*/}
      {(user.profil === "SADMIN" || user.profil === "ADMIN") && (
        <BasicModal
          showModal={showModal}
          setShowModal={setShowModal}
          title="Ajouter une agence"
          content={
            <AgenceForm
              showModal={showModal}
              refreshListAgence={refreshListAgence}
              setShowModal={setShowModal}
              refresh={refresh}
              setRefresh={setRefresh}
              edit={agence}
              setAgence={setAgence}
            ></AgenceForm>
          }
        ></BasicModal>
      )}
      {/* Modal Api Life Consent Form*/}
      <BasicModal
        showModal={showModalApi}
        setShowModal={setShowModalApi}
        title="Configurer l'API Life Consent"
        content={
          // @Todo Api Life Consent Form
          <>
            <LifeConsentApiForm
              agence={agence}
              setAgence={setAgence}
              setShowModalApi={setShowModalApi}
              refresh={refresh}
              setRefresh={setRefresh}
              refreshListAgence={refreshListAgence}
              requestHelper={requestHelper}
            ></LifeConsentApiForm>
          </>
        }
      ></BasicModal>

      {/* Spacer */}
      <Box sx={{ height: "2%" }}></Box>

      {/* Table */}
      <Box sx={_STYLES.secondaryContainer}>
        <Grid sx={{ width: "100%" }} container rowSpacing={1}>
          <Grid item xs={10}>
            <Typography
              variant="h5"
              style={{
                padding: "15px",
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Liste des agences
            </Typography>
          </Grid>
          <Grid item xl={2}>
            {(user.profil === "SADMIN" || user.profil === "ADMIN") && (
              <Button
                sx={btn(_COLORS.secondary, _COLORS.success)}
                variant="contained"
                onClick={showModalHandler}
                startIcon={<AddCircleOutlineIcon />}
              >
                Ajouter une Agence
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={_STYLES.dataGridContainer}>
              <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ height: "500px" }}
                rows={rowsAgence}
                columns={columns}
                disableSelectionOnClick={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ListAgences;
