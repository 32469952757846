import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";
import { RequestHelper } from "../../utils/requestHelper";
import {
  _ACTIONS,
  _COLORS,
  _MESSAGES,
  _SWAL_ICONS,
} from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";
import { createToast } from "../../utils/toast";
import { Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import VentilationPrix from "../forms/mandats/VentilationPrix";
import BoxActionMandat from "../forms/mandats/BoxActionMandat";
import CreationMandatVente from "../forms/mandats/CreationMandatVente";
import Mandant from "../forms/mandats/Mandant";
import DescriptionBienMandat from "../forms/mandats/DescriptionBienMandat";
import RemunerationMandataire from "../forms/mandats/RemunerationMandataire";
import ConditionsParticulieres from "../forms/mandats/ConditionsParticulieres";
import AnnexesDocumentaires from "../forms/mandats/AnnexesDocumentaires";

function Avenant() {
  const [currentAvenant, setCurrentAvenant] = useState([]);
  const currentIdUrl = useParams();
  const requestHelper = new RequestHelper();
  const [refreshMandat, setRefreshMandat] = useState(0);
  const [indices, setIndices] = useState([]);
  const [users, setUsers] = useState([]);
  const [typeVentes, setTypeVentes] = useState([]);
  const [typeMandants, setTypeMandants] = useState([]);
  const [civilites, setCivilites] = useState([]);
  const [typeBiens, setTypeBiens] = useState([]);
  const [typeDocuments, setTypeDocuments] = useState([]);

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "onChange	" });

  const getAvenantById = () => {
    requestHelper
      .doRequest(_ACTIONS.GET, `/api/mandats/${currentIdUrl.id}`)
      .then((response) => {
        if (response.status === 200) {
          setCurrentAvenant(response.data);
        } else {
          createToast(_SWAL_ICONS.error, _MESSAGES.loading_error).then(() =>
            Navigate("/mandats")
          );
        }
      });
  };

  const getIndice = () => {
    requestHelper.get("/api/indices").then((response) => {
      setIndices(response.data["hydra:member"]);
    });
  };

  const getUsers = () => {
    requestHelper
      .get("/api/users", {
        agence: currentAvenant.agence ? currentAvenant.agence["@id"] : "",
        represent: true,
      })
      .then((response) => {
        setUsers(response.data["hydra:member"]);
      });
  };

  /** Fonction pour refresh le mandat  */
  const refresh = () => {
    setRefreshMandat(refreshMandat + 1);
  };

  useEffect(() => {
    /* GET Mandat by Id */
    getAvenantById();
  }, [refreshMandat]);

  /* GET User Entity */
  useEffect(() => {
    getIndice();
    getTypeDeVente();
    getTypeMandant();
    getUsers();
    getCivilite();
    getTypeDeBien();
    getTypeDocument();
  }, []);

  const getTypeDeVente = () => {
    requestHelper.get("/api/type_ventes").then((response) => {
      setTypeVentes(response.data["hydra:member"]);
    });
  };

  const getTypeMandant = () => {
    requestHelper.get("/api/type_mandants").then((response) => {
      setTypeMandants(response.data["hydra:member"]);
    });
  };

  const getCivilite = () => {
    requestHelper.get("/api/civilites").then((response) => {
      setCivilites(response.data["hydra:member"]);
    });
  };

  const getTypeDeBien = () => {
    requestHelper.get("/api/type_biens").then((response) => {
      setTypeBiens(response.data["hydra:member"]);
    });
  };

  const getTypeDocument = () => {
    requestHelper.get("/api/type_documents").then((response) => {
      setTypeDocuments(response.data["hydra:member"]);
    });
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "2px solid rgb(238, 238, 238)",
          borderRadius: "10px",
          gap: "1rem",
          padding: 4,
          margin: 1,
        }}
      >
        <Grid sx={{ width: "100%" }}></Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          item
          xs={11}
          md={11}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
              paddingBottom: 20,
            }}
          >
            Modification d'un avenant au mandat de vente :{" "}
            {currentAvenant.numero}
          </Typography>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.success,
              paddingBottom: 20,
            }}
          >
            Avenant #{currentAvenant?.chronoAvenant}
          </Typography>
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild, backgroundColor: "#f7e0eb" }}>
          <VentilationPrix
            register={register}
            errors={errors}
            indices={indices}
            currentMandat={currentAvenant}
            setCurrentMandat={setCurrentAvenant}
            refresh={refresh}
            requestHelper={requestHelper}
          />
        </Grid>
        <BoxActionMandat
          requestHelper={requestHelper}
          currentMandat={currentAvenant}
          refresh={refresh}
        ></BoxActionMandat>
        <CreationMandatVente
          register={register}
          currentMandat={currentAvenant}
          setCurrentMandat={setCurrentAvenant}
          typeVentes={typeVentes}
          errors={errors}
          setTypeVentes={setTypeVentes}
          mode="avenant"
        ></CreationMandatVente>
        <Mandant
          register={register}
          errors={errors}
          typeMandants={typeMandants}
          setTypeMandants={setTypeMandants}
          currentMandat={currentAvenant}
          setCurrentMandat={setCurrentAvenant}
          civilites={civilites}
          refresh={refresh}
          requestHelper={requestHelper}
        />
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <DescriptionBienMandat
            register={register}
            errors={errors}
            typeBiens={typeBiens}
            currentMandat={currentAvenant}
            setCurrentMandat={setCurrentAvenant}
            refresh={refresh}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <RemunerationMandataire
            currentMandat={currentAvenant}
            setCurrentMandat={setCurrentAvenant}
            refresh={refresh}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <ConditionsParticulieres
            currentMandat={currentAvenant}
            setCurrentMandat={setCurrentAvenant}
            refresh={refresh}
          />
        </Grid>
        <Grid sx={_STYLES.sxGridChild}>
          <AnnexesDocumentaires
            errors={errors}
            register={register}
            typeDocuments={typeDocuments}
            refresh={refresh}
            currentMandat={currentAvenant}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Avenant;
