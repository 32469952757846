import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import { Box, Button, Grid } from "@mui/material";
import { _ACTIONS, _COLORS } from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";
import ListeMandats from "./ListMandats";
import { RequestHelper } from "../../utils/requestHelper";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BasicModal from "../helpers/BasicModal";
import ActualiteForm from "../forms/ActualiteForm";
import { UserContext } from "../contexts/UserContext";

function Dashboard() {
  const [showModal, setShowModal] = useState(false);
  const [actu, setActu] = useState("");

  const [clickedChoice, setClickedChoice] = useState("");
  const [infoHtml, setInfoHtml] = useState(``);
  const [dataMandat, setDataMandat] = useState([]);
  const { user, profil } = useContext(UserContext);

  const requestHelper = new RequestHelper();

  const getMandatsNumber = () => {
    requestHelper
      .doRequest(_ACTIONS.POST, "/api/mandat-numbers", { user: user.id })
      .then((response) => {
        if (response.status === 200) setDataMandat(response.data.data);
      });
  };

  const getActu = () => {
    requestHelper
      .doRequest(_ACTIONS.GET, "/api/actualites", {
        "order[createdAt]": "DESC",
        limit: "1",
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data["hydra:member"].length > 0
        ) {
          setActu(response.data["hydra:member"][0].content);
        } else {
          setActu(infoHtml);
        }
      });
  };

  useEffect(() => {
    getMandatsNumber();
    getActu();
  }, []);

  /**
   * Informe à la première connexion que l'utilisateur doit vérifier sir les données de l'agence sont à jour
   */
  useEffect(() => {
    if (user.firstConnexion) {
      Swal.fire(
        "Important",
        "Merci de vérfier que les données de l'agence sont à jour"
      );
    }
  }, []);

  /**
   * Handler for showing/hiding the modal for adding/editing an agency.
   */
  function showModalHandler() {
    setShowModal(!showModal);
  }

  return (
    <>
      <Box
        sx={{
          ..._STYLES.mainContainer,
          ...{
            alignItems: "center",
          },
        }}
      >
        <Grid columnSpacing={2} container>
          <Grid item xl={1}></Grid>
          <Grid item xl={2} onClick={() => setClickedChoice("Brouillon")}>
            <Box
              sx={{
                ..._STYLES.dashboardItem,
                ...{ backgroundColor: _COLORS.light },
              }}
            >
              <h2>{dataMandat.B ?? 0}</h2>
              <h2> Brouillon(s)</h2>
            </Box>
          </Grid>
          <Grid xl={2} item onClick={() => setClickedChoice("A signer")}>
            <Box
              sx={{
                ..._STYLES.dashboardItem,
                ...{ backgroundColor: _COLORS.success },
              }}
            >
              <h2>{dataMandat.AS ?? 0}</h2>
              <h2>Mandat(s) sans signature</h2>
            </Box>
          </Grid>
          <Grid
            xl={2}
            item
            onClick={() => setClickedChoice("En cours de signature")}
          >
            <Box
              sx={{
                ..._STYLES.dashboardItem,
                ...{ backgroundColor: _COLORS.primaryLight },
              }}
            >
              <h2>{dataMandat.ECS ?? 0}</h2>
              <h2>Mandat(s) en cours de signature</h2>
            </Box>
          </Grid>
          <Grid xl={2} item onClick={() => setClickedChoice("Signé")}>
            <Box
              sx={{
                ..._STYLES.dashboardItem,
                ...{ backgroundColor: _COLORS.yellow },
              }}
            >
              <h2>{dataMandat.S ?? 0}</h2>
              <h2> Mandat(s) terminés</h2>
            </Box>
          </Grid>
          <Grid xl={2} item onClick={() => setClickedChoice("Annulé")}>
            <Box
              sx={{
                ..._STYLES.dashboardItem,
                ...{ backgroundColor: _COLORS.secondary },
              }}
            >
              <h2>{dataMandat.A ?? 0}</h2>
              <h2> Mandat(s) annulés</h2>
            </Box>
          </Grid>
          <Grid item xl={1}></Grid>
        </Grid>

        {/* Spacer */}
        <Box sx={{ height: "2%" }}></Box>

        {/* Zone de texte 1 */}
        <Box
          sx={{
            width: "100%",
            backgroundColor: _COLORS.ultraLight,
            borderRadius: "10px",
            padding: "25px",
          }}
        >
          {/* Bouton d'édition du message d'accueil */}
          {user.profil === "ADMIN" ||
            (user.profil === "SADMIN" && (
              <Button
                onClick={showModalHandler}
                sx={{
                  float: "right",
                  color: _COLORS.primary,
                }}
              >
                Editer le message d'accueil
                <EditOutlinedIcon />
              </Button>
            ))}
          {/* Modal */}
          <BasicModal
            showModal={showModal}
            setShowModal={setShowModal}
            title="Mise a jour du message d'actualité"
            content={
              <ActualiteForm
                requestHelper={requestHelper}
                content={actu}
                setContent={setActu}
                setShowModal={setShowModal}
              ></ActualiteForm>
            }
          />
          <div id="infoHtml" dangerouslySetInnerHTML={{ __html: actu }}></div>
        </Box>
        {/* Spacer */}
        <Box sx={{ height: "2%" }}></Box>

        {/* Liste des Mandats */}
        <ListeMandats clickedChoice={clickedChoice} />
      </Box>
    </>
  );
}

export default Dashboard;
