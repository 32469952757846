import {
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { _ACTIONS, _COLORS } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { InputAdornment } from "@mui/material";
import _ from "lodash";
import Checkbox from "@mui/material/Checkbox";
import { getPropertyValueFromMultiArray } from "../../../utils/helper";

function DetailRecherche({
  currentMandat,
  setCurrentMandat,
  refresh,
  register,
  errors,
  requestHelper,
}) {
  const updateTypeVente = (e) => {
    let typeVentes = getPropertyValueFromMultiArray(
      currentMandat?.typeDeVentesRecherche,
      "@id",
      true
    );

    if (e.target.checked && _.indexOf(typeVentes, e.target.value) === -1) {
      typeVentes.push(e.target.value);
    } else if (!e.target.checked) {
      typeVentes = _.without(typeVentes, e.target.value);
    }
    requestHelper
      .doRequest(_ACTIONS.PUT, `${currentMandat["@id"]}`, {
        typeDeVentesRecherche: typeVentes,
      })
      .then((response) => {
        if (response.status === 200) {
          setCurrentMandat(response.data);
        }
      });
  };

  return (
    <>
      <Grid container columnSpacing={1} rowSpacing={4} padding={4}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Detail de la recherche +
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} alignItems={"center"} display={"flex"}>
          <Grid item xs={4} md={2}>
            <Typography
              sx={{
                ..._STYLES.typographyLabelTitle,
                ...{ color: _COLORS.primary },
              }}
            >
              Désignation et usage du bien :
            </Typography>
          </Grid>
          <Grid item md={7} xs={11} display={"flex"}>
            <TextField
              size="large"
              fullWidth
              multiline
              id="designationBienRecherche"
              name="designationBienRecherche"
              type="text"
              label=""
              value={
                currentMandat?.designationBienRecherche
                  ? currentMandat.designationBienRecherche
                  : ""
              }
              {...register("designationBienRecherche", {
                onChange: (e) => {
                  setCurrentMandat({
                    ...currentMandat,
                    designationBienRecherche: e.target.value,
                  });
                },
                onBlur: (e) => {
                  editInstantRequest(
                    e.target.value,
                    currentMandat["@id"],
                    "designationBienRecherche"
                  );
                },
              })}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} alignItems={"center"} display={"flex"}>
          <Grid item xs={4} md={2}>
            <Typography
              sx={{
                ..._STYLES.typographyLabelTitle,
                ...{ color: _COLORS.primary },
              }}
            >
              Localisations de recherche :
            </Typography>
          </Grid>
          <Grid item md={7} xs={11} display={"flex"}>
            <TextField
              size="large"
              fullWidth
              multiline
              id="localisationBienRecherche"
              name="localisationBienRecherche"
              type="text"
              label=""
              value={
                currentMandat?.localisationBienRecherche
                  ? currentMandat.localisationBienRecherche
                  : ""
              }
              {...register("localisationBienRecherche", {
                onChange: (e) => {
                  setCurrentMandat({
                    ...currentMandat,
                    localisationBienRecherche: e.target.value,
                  });
                },
                onBlur: (e) => {
                  editInstantRequest(
                    e.target.value,
                    currentMandat["@id"],
                    "localisationBienRecherche"
                  );
                },
              })}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} alignItems={"center"} display={"flex"}>
          <Grid item xs={4} md={2}>
            <Typography
              sx={{
                ..._STYLES.typographyLabelTitle,
                ...{ color: _COLORS.primary },
              }}
            >
              Ventilation
            </Typography>
          </Grid>
          <Grid item md={4} xs={11} display={"flex"}>
            <TextField
              size="small"
              id="bouquetRecherche"
              name="bouquetRecherche"
              type="number"
              label="Bouquet (maximal)"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              value={
                currentMandat?.bouquetRecherche
                  ? currentMandat.bouquetRecherche
                  : ""
              }
              {...register("bouquetRecherche", {
                onChange: (e) => {
                  setCurrentMandat({
                    ...currentMandat,
                    bouquetRecherche: e.target.value,
                  });
                },
                onBlur: (e) => {
                  editInstantRequest(
                    e.target.value,
                    currentMandat["@id"],
                    "bouquetRecherche"
                  );

                  refresh();
                },
                validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                setValueAs: (value) => {
                  return _.isEmpty(value) ? "" : parseFloat(value);
                },
              })}
            />
            {errors.bouquetRecherche && (
              <Typography sx={_STYLES.errorMessage}>
                Cette valeur doit être une donné numérique
              </Typography>
            )}
          </Grid>
          <Grid
            item
            md={4}
            xs={11}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <TextField
              size="small"
              id="renteViagereRecherche"
              name="renteViagereRecherche"
              type="number"
              label="Rente viagère souhaitée maxi"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              value={
                currentMandat?.renteViagereRecherche
                  ? currentMandat.renteViagereRecherche
                  : ""
              }
              {...register("renteViagereRecherche", {
                onChange: (e) => {
                  setCurrentMandat({
                    ...currentMandat,
                    renteViagereRecherche: e.target.value,
                  });
                },
                onBlur: (e) => {
                  editInstantRequest(
                    e.target.value,
                    currentMandat["@id"],
                    "renteViagereRecherche"
                  );

                  refresh();
                },
                validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                setValueAs: (value) => {
                  return _.isEmpty(value) ? "" : parseFloat(value);
                },
              })}
            />
            {errors.renteViagereRecherche && (
              <Typography sx={_STYLES.errorMessage}>
                Cette valeur doit être une donné numérique
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} alignItems={"center"} display={"flex"}>
          <Grid item xs={6} md={2}>
            <Typography
              sx={{
                ..._STYLES.typographyLabelTitle,
                ...{ color: _COLORS.primary },
              }}
            >
              Type de Vente
            </Typography>
          </Grid>
          <Grid item xs={11} md={4} display={"flex"} flexDirection={"column"}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      _.indexOf(
                        getPropertyValueFromMultiArray(
                          currentMandat?.typeDeVentesRecherche,
                          "@id"
                        ),
                        "/api/type_ventes/1"
                      ) !== -1
                    }
                    value={"/api/type_ventes/1"}
                    onChange={(e) => {
                      updateTypeVente(e);
                    }}
                  ></Checkbox>
                }
                label="Habitation / Viager Occupé"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      _.indexOf(
                        getPropertyValueFromMultiArray(
                          currentMandat?.typeDeVentesRecherche,
                          "@id"
                        ),
                        "/api/type_ventes/2"
                      ) != -1
                    }
                    value={"/api/type_ventes/2"}
                    onChange={(e) => {
                      updateTypeVente(e);
                    }}
                  ></Checkbox>
                }
                label="Habitation / Viager Libre"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      _.indexOf(
                        getPropertyValueFromMultiArray(
                          currentMandat?.typeDeVentesRecherche,
                          "@id"
                        ),
                        "/api/type_ventes/3"
                      ) != -1
                    }
                    value={"/api/type_ventes/3"}
                    onChange={(e) => {
                      updateTypeVente(e);
                    }}
                  ></Checkbox>
                }
                label="Habitation / Viager Vente au comptant avec réserve du droit
                d'usage et d'habitation"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      _.indexOf(
                        getPropertyValueFromMultiArray(
                          currentMandat?.typeDeVentesRecherche,
                          "@id"
                        ),
                        "/api/type_ventes/4"
                      ) != -1
                    }
                    value={"/api/type_ventes/4"}
                    onChange={(e) => {
                      updateTypeVente(e);
                    }}
                  ></Checkbox>
                }
                label="Habitation / Vente en nue-propriété avec réserve d'usufruit"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      _.indexOf(
                        getPropertyValueFromMultiArray(
                          currentMandat?.typeDeVentesRecherche,
                          "@id"
                        ),
                        "/api/type_ventes/6"
                      ) != -1
                    }
                    value={"/api/type_ventes/6"}
                    onChange={(e) => {
                      updateTypeVente(e);
                    }}
                  ></Checkbox>
                }
                label="Habitation / Vente à terme Occupé"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      _.indexOf(
                        getPropertyValueFromMultiArray(
                          currentMandat?.typeDeVentesRecherche,
                          "@id"
                        ),
                        "/api/type_ventes/5"
                      ) != -1
                    }
                    value={"/api/type_ventes/5"}
                    onChange={(e) => {
                      updateTypeVente(e);
                    }}
                  ></Checkbox>
                }
                label="Habitation / Vente à terme Libre"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DetailRecherche;
