import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  _ACTIONS,
  _COLORS,
  _MESSAGES,
  _SWAL_ICONS,
} from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";
import DescriptionBienMandat from "../forms/mandats/DescriptionBienMandat";
import RemunerationMandataire from "../forms/mandats/RemunerationMandataire";
import ConditionsParticulieres from "../forms/mandats/ConditionsParticulieres";
import VentilationPrix from "../forms/mandats/VentilationPrix";
import CreationMandatVente from "../forms/mandats/CreationMandatVente";
import AnnexesDocumentaires from "../forms/mandats/AnnexesDocumentaires";
import { useForm } from "react-hook-form";
import { RequestHelper } from "../../utils/requestHelper";
import Mandant from "../forms/mandats/Mandant";
import { useParams } from "react-router";
import _ from "lodash";
import { editInstantRequest } from "../../utils/formHelper";
import BoxActionMandat from "../forms/mandats/BoxActionMandat";
import { UserContext } from "../contexts/UserContext";

function MandatVente() {
  const [typeVentes, setTypeVentes] = useState([]);
  const [typeMandants, setTypeMandants] = useState([]);
  const [civilites, setCivilites] = useState([]);
  const [typeBiens, setTypeBiens] = useState([]);
  const [indices, setIndices] = useState([]);

  const [users, setUsers] = useState([]);
  const [typeDocuments, setTypeDocuments] = useState([]);
  const [currentMandat, setCurrentMandat] = useState([]);
  const [refreshMandat, setRefreshMandat] = useState(0);
  const currentIdUrl = useParams();
  const { user } = React.useContext(UserContext);

  const requestHelper = new RequestHelper();

  const sxGridChild = {
    width: "100%",
    border: "2px solid rgb(238, 238, 238)",
    borderRadius: "10px",
    marginTop: 2,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange	" });

  /* GET User Entity */
  useEffect(() => {
    getUsers();
    getIndice();
    getTypeDocument();
    getTypeDeBien();
    getCivilite();
    getTypeMandant();
    getTypeDeVente();
  }, []);

  useEffect(() => {
    /* GET Mandat by Id */
    getMandatById();
  }, [refreshMandat]);
  /* Va en haut de la page à l'ouverture */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getTypeDeVente = () => {
    requestHelper.get("/api/type_ventes").then((response) => {
      /** Filtre Pour gestion Viagére à mettre uniquement pour admin et sadmin */
      let arrayList = response.data["hydra:member"];
      arrayList.forEach((item) => {
        if (
          item.nom == "Gestion viagère" &&
          (user.profil == "SUSER" || user.profil == "USER")
        ) {
          var index = arrayList.indexOf(item);
          if (index !== -1) arrayList.splice(index, 1);
        }
      });
      setTypeVentes(arrayList);
    });
  };

  const getTypeMandant = () => {
    requestHelper.get("/api/type_mandants").then((response) => {
      setTypeMandants(response.data["hydra:member"]);
    });
  };

  const getCivilite = () => {
    requestHelper.get("/api/civilites").then((response) => {
      setCivilites(response.data["hydra:member"]);
    });
  };

  const getTypeDeBien = () => {
    requestHelper.get("/api/type_biens").then((response) => {
      setTypeBiens(response.data["hydra:member"]);
    });
  };

  const getIndice = () => {
    requestHelper.get("/api/indices").then((response) => {
      setIndices(response.data["hydra:member"]);
    });
  };

  const getUsers = () => {
    requestHelper
      .get("/api/users", {
        agence: currentMandat.agence ? currentMandat.agence["@id"] : "",
        represent: true,
      })
      .then((response) => {
        setUsers(response.data["hydra:member"]);
      });
  };

  const getTypeDocument = () => {
    requestHelper.get("/api/type_documents").then((response) => {
      setTypeDocuments(response.data["hydra:member"]);
    });
  };

  const getMandatById = () => {
    requestHelper
      .doRequest(_ACTIONS.GET, `/api/mandats/${currentIdUrl.id}`)
      .then((response) => {
        if (response.status === 200) {
          setCurrentMandat(response.data);
        }
      });
  };

  /** Fonction pour refresh le mandat  */
  const refresh = () => {
    setRefreshMandat(refreshMandat + 1);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "2px solid rgb(238, 238, 238)",
          borderRadius: "10px",
          gap: "1rem",
          padding: 4,
          margin: 1,
        }}
      >
        <Grid sx={{ width: "100%" }}>
          <CreationMandatVente
            register={register}
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            typeVentes={typeVentes}
            errors={errors}
            setTypeVentes={setTypeVentes}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <Mandant
            register={register}
            errors={errors}
            typeMandants={typeMandants}
            setTypeMandants={setTypeMandants}
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            civilites={civilites}
            refresh={refresh}
            requestHelper={requestHelper}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <DescriptionBienMandat
            register={register}
            errors={errors}
            typeBiens={typeBiens}
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            refresh={refresh}
          />
        </Grid>

        <Grid sx={{ ..._STYLES.sxGridChild, backgroundColor: "#f7e0eb" }}>
          <VentilationPrix
            register={register}
            errors={errors}
            indices={indices}
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            refresh={refresh}
            requestHelper={requestHelper}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <RemunerationMandataire
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            refresh={refresh}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <ConditionsParticulieres
            currentMandat={currentMandat}
            setCurrentMandat={setCurrentMandat}
            refresh={refresh}
          />
        </Grid>
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <Box sx={{ flexGrow: 1, padding: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.primary,
                  }}
                >
                  Signatures
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
                <Typography>Signataire des mandataires</Typography>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel> Signatiaire :</InputLabel>
                    <Select
                      label="Signatiaire :"
                      size="small"
                      {...register("signataire", { required: false })}
                      value={
                        currentMandat?.signataire
                          ? currentMandat?.signataire?.["@id"]
                          : ""
                      }
                      onChange={(e) => {
                        let value =
                          e.target.value === "null" ? null : e.target.value;
                        let m = editInstantRequest(
                          value,
                          currentMandat["@id"],
                          "signataire"
                        );

                        m.then((m) => {
                          if (m["@context"] === "/api/contexts/Error") {
                            refresh();
                          } else {
                            setCurrentMandat(m);
                          }
                        });
                      }}
                    >
                      <MenuItem value="null">Aucun Signataire</MenuItem>
                      {users.map((u, i) => (
                        <MenuItem key={i} value={u["@id"]}>
                          {u.prenom + " " + u.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid sx={_STYLES.sxGridChild}>
          <AnnexesDocumentaires
            errors={errors}
            register={register}
            typeDocuments={typeDocuments}
            refresh={refresh}
            currentMandat={currentMandat}
          />
        </Grid>
        <BoxActionMandat
          requestHelper={requestHelper}
          refresh={refresh}
          currentMandat={currentMandat}
        ></BoxActionMandat>
      </Grid>
    </>
  );
}

export default MandatVente;
