import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _COLORS } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import EuroIcon from "@mui/icons-material/Euro";
import { editInstantRequest } from "../../../utils/formHelper";
import _ from "lodash";
import { NumericFormat } from "react-number-format";

function DescriptionBienMandat({
  register,
  errors,
  typeBiens,
  currentMandat,
  setCurrentMandat,
  refresh,
}) {
  const [valeurVenale, setValeurVenale] = useState("");
  let des =
    currentMandat?.typeBien?.nomTechnique == "M"
      ? "Une maison ........... composée ..."
      : "Un appartement ..............de type T... composé ...";

  useEffect(() => {
    setComposeDe(currentMandat?.bien?.composition);
  }, [currentMandat]);

  const updateValeurBien = (value) => {
    currentMandat.bien.valeurBiens.filter(
      (v) => v.mandat === currentMandat["@id"]
    )[0].valeurVenale = value;
    setCurrentMandat({
      ...currentMandat,
      bien: {
        ...currentMandat.bien,
        valeursBiens: {
          ...currentMandat.bien.valeursBiens,
        },
      },
    });
    setValeurVenale(value);
  };

  const [composeDe, setComposeDe] = useState("");

  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        padding={4}
        sx={{ alignItems: "center" }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Description du bien
          </Typography>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Type de bien*
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <InputLabel size="small"> Type de bien</InputLabel>
            <Select
              label="Type de bien"
              size="small"
              disabled={currentMandat?.type === "avenant"}
              value={
                currentMandat?.typeBien ? currentMandat?.typeBien["@id"] : ""
              }
              {...register("typeBien", { required: false })}
              id="typeBien"
              onChange={(e) => {
                let m = editInstantRequest(
                  e.target.value,
                  currentMandat["@id"],
                  "typeBien"
                );

                m.then((m) => {
                  setCurrentMandat(m);
                });
              }}
            >
              {typeBiens.map((typeBien, i) => (
                <MenuItem key={i} value={typeBien["@id"]}>
                  {typeBien.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} />
        <Grid item md={2} xs={12}>
          <Typography noWrap sx={{ ..._STYLES.typographyLabelTitle }}>
            Adresse du bien*
          </Typography>
        </Grid>
        <Grid item md={10} xs={12}>
          <TextField
            fullWidth
            size="small"
            id="adressBien"
            disabled={currentMandat?.type === "avenant"}
            label="Adresse du bien"
            value={
              currentMandat?.bien?.adresse ? currentMandat.bien.adresse : ""
            }
            // Besoin de changer la valeur du state pour que le composant se rafraichisse
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  adresse: e.target.value,
                },
              });
            }}
            // On envoie la valeur au serveur
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "adresse"
              );

              refresh();
            }}
            helperText={
              errors?.biens?.adresse?.type === "required" && (
                <Typography sx={_STYLES.errorMessage}>
                  Adresse est requis
                </Typography>
              )
            }
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Parcelle cadastrale *
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            size="small"
            fullWidth
            id="cadastre"
            disabled={currentMandat?.type === "avenant"}
            value={
              currentMandat?.bien?.cadastre ? currentMandat.bien.cadastre : ""
            }
            {...register("biens.cadastre", {
              required: false,
            })}
            // Besoin de changer la valeur du state pour que le composant se rafraichisse
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  cadastre: e.target.value,
                },
              });
            }}
            // On envoie la valeur au serveur
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "cadastre"
              );

              refresh();
            }}
          />
        </Grid>
        <Grid item md={6} />
        {currentMandat?.typeVente?.nom !== "Viager Mixte" && (
          <>
            <Grid item md={2} xs={12}>
              <Typography noWrap sx={{ ..._STYLES.typographyLabelTitle }}>
                Composé de*
              </Typography>
            </Grid>
            <Grid item md={10} xs={12}>
              <TextField
                fullWidth
                size="large"
                id="composition"
                disabled={currentMandat?.type === "avenant"}
                multiline={true}
                rows={5}
                value={composeDe}
                label="Composition du bien"
                // Besoin de changer la valeur du state pour que le composant se rafraichisse
                onChange={(e) => {
                  setComposeDe(e.target.value);
                }}
                // On envoie la valeur au serveur
                onBlur={(e) => {
                  let m = editInstantRequest(
                    e.target.value,
                    currentMandat.bien["@id"],
                    "composition"
                  );

                  refresh();
                }}
              />
            </Grid>
          </>
        )}

        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Nombre de pièce T
          </Typography>
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            size="small"
            fullWidth
            id="appartementT"
            disabled={currentMandat?.type === "avenant"}
            value={
              currentMandat?.bien?.appartementT
                ? currentMandat.bien.appartementT
                : ""
            }
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  appartementT: e.target.value,
                },
              });
            }}
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "appartementT"
              );

              refresh();
            }}
            label="Nombre de pièce T ..."
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>Lots</Typography>
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            size="small"
            fullWidth
            id="lot"
            disabled={currentMandat?.type === "avenant"}
            value={currentMandat?.bien?.lot ? currentMandat.bien.lot : ""}
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  lot: e.target.value,
                },
              });
            }}
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "lot"
              );

              refresh();
            }}
            label="Lot"
          />
        </Grid>
        <Grid sx={{ ..._STYLES.typographyLabelTitle }} item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Tantième
          </Typography>
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            size="small"
            fullWidth
            id="tantieme"
            disabled={currentMandat?.type === "avenant"}
            value={
              currentMandat?.bien?.tantieme ? currentMandat.bien.tantieme : ""
            }
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                bien: {
                  ...currentMandat.bien,
                  tantieme: e.target.value,
                },
              });
            }}
            onBlur={(e) => {
              let m = editInstantRequest(
                e.target.value,
                currentMandat.bien["@id"],
                "tantieme"
              );

              refresh();
            }}
            label="Tantième"
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Surface habitable*
          </Typography>
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            size="small"
            fullWidth
            id="surface"
            type="number"
            label="Surface habitable"
            disabled={currentMandat?.type === "avenant"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  m² selon propriétaire
                </InputAdornment>
              ),
            }}
            value={
              currentMandat?.bien?.surface ? currentMandat.bien.surface : ""
            }
            {...register("surface", {
              onChange: (e) => {
                setCurrentMandat({
                  ...currentMandat,
                  bien: {
                    ...currentMandat.bien,
                    surface: e.target.value,
                  },
                });
              },
              onBlur: (e) => {
                let m = editInstantRequest(
                  parseFloat(e.target.value),
                  currentMandat.bien["@id"],
                  "surface"
                );

                refresh();
              },
              validate: (value) => !_.isNaN(value) || _.isEmpty(value),
              setValueAs: (value) => {
                return _.isEmpty(value) ? "" : parseFloat(value);
              },
            })}
          />
        </Grid>
        {currentMandat?.type != "avenant" && (
          <>
            <Grid item md={2} xs={12}>
              <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
                Valeur vénales*
              </Typography>
            </Grid>

            <Grid
              item
              md={4}
              xs={11}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <NumericFormat
                thousandSeparator=" "
                customInput={TextField}
                allowNegative={false}
                decimalScale={2}
                displayType="input"
                allowedDecimalSeparators={["."]}
                size="small"
                fullWidth
                id="valeurVenale"
                label="Valeur Vénales"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
                value={
                  currentMandat?.bien?.valeurBiens
                    ? currentMandat.bien.valeurBiens.filter(
                        (v) => v.mandat === currentMandat["@id"]
                      )[0].valeurVenale
                    : ""
                }
                {...register("valeurVenale", {
                  onChange: (e) => {
                    updateValeurBien(e.target.value);
                  },
                  onBlur: (e) => {
                    let m = editInstantRequest(
                      e.target.value,
                      currentMandat?.bien?.valeurBiens.filter(
                        (v) => v.mandat === currentMandat["@id"]
                      )[0]["@id"],
                      "valeurVenale"
                    );

                    refresh();
                  },
                  validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                  setValueAs: (value) => {
                    return _.isEmpty(value) ? "" : value;
                  },
                })}
              />{" "}
              {errors.valeurVenale && (
                <Typography sx={_STYLES.errorMessage}>
                  Cette valeur doit être une donné numérique
                </Typography>
              )}
            </Grid>
          </>
        )}
        <Grid item md={5} xs={12}></Grid>
        {currentMandat.typeVente &&
          currentMandat.typeVente["@id"] == "/api/type_ventes/7" && (
            <>
              <Grid sx={{ marginTop: "1.5em" }} item md={12} xs={12}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.primary,
                  }}
                  variant="h5"
                >
                  Description du bien en Viager Mixte :
                </Typography>
              </Grid>
              <Grid item md={2} xs={11} display={"flex"}>
                <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
                  En Viager Occupé :
                </Typography>
              </Grid>
              <Grid item md={10} xs={12}>
                <TextField
                  fullWidth
                  size="large"
                  id="descriptionViagerOccupe"
                  disabled={currentMandat?.type === "avenant"}
                  multiline={true}
                  rows={5}
                  value={
                    currentMandat?.bien?.descriptionViagerOccupe
                      ? currentMandat.bien.descriptionViagerOccupe
                      : ""
                  }
                  label={des} // Besoin de changer la valeur du state pour que le composant se rafraichisse
                  onChange={(e) => {
                    setCurrentMandat({
                      ...currentMandat,
                      bien: {
                        ...currentMandat.bien,
                        descriptionViagerOccupe: e.target.value,
                      },
                    });
                  }}
                  // On envoie la valeur au serveur
                  onBlur={(e) => {
                    let m = editInstantRequest(
                      e.target.value,
                      currentMandat.bien["@id"],
                      "descriptionViagerOccupe"
                    );

                    refresh();
                  }}
                />
              </Grid>
              <Grid item md={2} xs={11} display={"flex"}>
                <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
                  Viager Libre :
                </Typography>
              </Grid>
              <Grid item md={10} xs={12}>
                <TextField
                  fullWidth
                  size="large"
                  id="descriptionViagerLibre"
                  disabled={currentMandat?.type === "avenant"}
                  multiline={true}
                  rows={5}
                  value={
                    currentMandat?.bien?.descriptionViagerLibre
                      ? currentMandat.bien.descriptionViagerLibre
                      : ""
                  }
                  label={des}
                  // Besoin de changer la valeur du state pour que le composant se rafraichisse
                  onChange={(e) => {
                    setCurrentMandat({
                      ...currentMandat,
                      bien: {
                        ...currentMandat.bien,
                        descriptionViagerLibre: e.target.value,
                      },
                    });
                  }}
                  // On envoie la valeur au serveur
                  onBlur={(e) => {
                    let m = editInstantRequest(
                      e.target.value,
                      currentMandat.bien["@id"],
                      "descriptionViagerLibre"
                    );

                    refresh();
                  }}
                />
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
}

export default DescriptionBienMandat;
