import React, { useState, useEffect, useNavigate } from "react";
import { Grid } from "@mui/material";
import CreationMandatVente from "../forms/mandats/CreationMandatVente";
import Mandant from "../forms/mandats/Mandant";
import { useForm } from "react-hook-form";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS } from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";
import { useParams } from "react-router";
import DetailRecherche from "../forms/mandats/DetailRecherche";
import RemunerationMandataire from "../forms/mandats/RemunerationMandataire";
import ConditionsParticulieres from "../forms/mandats/ConditionsParticulieres";
import BoxActionMandat from "../forms/mandats/BoxActionMandat";

function MandatRecherche() {
  const [currentMandat, setCurrentMandat] = useState([]);
  const [typeMandants, setTypeMandants] = useState([]);
  const [civilites, setCivilites] = useState([]);
  const [refreshMandat, setRefreshMandat] = useState(0);
  const [typeVentes, setTypeVentes] = useState([]);
  const currentIdUrl = useParams();

  const requestHelper = new RequestHelper();

  /* GET User Entity */
  useEffect(() => {
    getCivilite();
    getTypeMandant();
    getTypeVente();
  }, []);

  useEffect(() => {
    /* GET Mandat by Id */
    getMandatById();
  }, [refreshMandat]);

  const getTypeMandant = () => {
    requestHelper.get("/api/type_mandants").then((response) => {
      setTypeMandants(response.data["hydra:member"]);
    });
  };

  const getCivilite = () => {
    requestHelper.get("/api/civilites").then((response) => {
      setCivilites(response.data["hydra:member"]);
    });
  };

  const getTypeVente = () => {
    requestHelper.get("/api/type_ventes").then((response) => {
      setTypeVentes(response.data["hydra:member"]);
    });
  };

  const getMandatById = () => {
    requestHelper
      .doRequest(_ACTIONS.GET, `/api/mandats/${currentIdUrl.id}`)
      .then((response) => {
        if (response.status === 200) {
          setCurrentMandat(response.data);
        }
      });
  };

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "onChange	" });

  /** Fonction pour refresh le mandat  */
  const refresh = () => {
    setRefreshMandat(refreshMandat + 1);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        border: "2px solid rgb(238, 238, 238)",
        borderRadius: "10px",
        gap: "1rem",
        padding: 4,
        margin: 1,
      }}
    >
      <Grid sx={{ width: "100%" }}>
        <CreationMandatVente
          register={register}
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          typeVentes={typeVentes}
          errors={errors}
          setTypeVentes={setTypeVentes}
          mode="recherche"
        />
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <Mandant
          register={register}
          errors={errors}
          typeMandants={typeMandants}
          setTypeMandants={setTypeMandants}
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          civilites={civilites}
          refresh={refresh}
          requestHelper={requestHelper}
        />
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <DetailRecherche
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          refresh={refresh}
          register={register}
          errors={errors}
          requestHelper={requestHelper}
        ></DetailRecherche>
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <RemunerationMandataire
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          refresh={refresh}
        />
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <ConditionsParticulieres
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          refresh={refresh}
        />
      </Grid>
      <BoxActionMandat
        requestHelper={requestHelper}
        currentMandat={currentMandat}
        refresh={refresh}
      ></BoxActionMandat>
    </Grid>
  );
}

export default MandatRecherche;
