import _ from "lodash";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { _COLORS } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { createToast } from "../../../utils/toast";
import Ventilation from "./Ventilation";
import { NumericFormat } from "react-number-format";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// liste des types de vente ayant besoin des champs comptant et mensualités avec durée
// Modifier dans le back MandatService->setVentilations dans le switch
export let arrayMensualite = [];
arrayMensualite.push("Nue-propriété");
arrayMensualite.push("Vente au comptant");
arrayMensualite.push("Vente Mixte");
arrayMensualite.push("Vente à terme occupée");
arrayMensualite.push("Vente à terme libre");

// liste des types de vente ayant besoin du champ augmentation de la rente/mensualite
let arrayAugmentation = [];
arrayAugmentation.push("Viager occupé");
arrayAugmentation.push("Viager Libre");
arrayAugmentation.push("Viager Mixte");
arrayAugmentation.push("Gestion viagère");

function VentilationPrix({
  register,
  errors,
  indices,
  currentMandat,
  setCurrentMandat,
  refresh,
  requestHelper,
}) {
  const mandatVenteComptant =
    currentMandat?.typeVente?.nom === "Vente au comptant";
  const mandatVenteNuePropriete =
    currentMandat?.typeVente?.nom === "Nue-propriété";
  const mandatVenteTermeLibre =
    currentMandat?.typeVente?.nom === "Vente à terme libre";
  const mandatVenteMixte = currentMandat?.typeVente?.nom === "Viager Mixte";
  const mandatVenteTermeOccupee =
    currentMandat?.typeVente?.nom === "Vente à terme occupée";
  const showRente = !!(
    currentMandat?.typeVente?.nom &&
    !arrayMensualite.includes(currentMandat.typeVente.nom)
  );
  const showAugmentation =
    currentMandat?.typeVente?.nom &&
    arrayAugmentation.includes(currentMandat.typeVente.nom);

  const createVentilation = (e, mixte = false) => {
    let data = {
      selected: false,
      bouquet: 0,
      renteViagere: 0,
      mandat: currentMandat["@id"],
    };

    if (mixte) {
      mixte === "occupe"
        ? (data.isVMViagerOccupe = true)
        : (data.isVMViagerLibre = true);
    }

    requestHelper.doRequest("POST", "/api/ventilations", data).then((res) => {
      if (res.status === 201) {
        createToast("success", "Ventilation créée avec succès");

        setCurrentMandat({
          ...currentMandat,
          ventilations: [...currentMandat.ventilations, res.data],
        });
      } else {
        createToast("error", "Erreur lors de la création de la ventilation");
      }
    });
  };

  useEffect(() => {}, [currentMandat]);

  return (
    <>
      <Box sx={{ padding: 4, flexGrow: 1 }}>
        <Grid container columnSpacing={1} rowSpacing={1} padding={2}>
          {currentMandat.type == "avenant" && (
            <>
              <Grid
                item
                md={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.secondary,
                  }}
                >
                  MODIFICATIONS DU MANDAT
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: _COLORS.primary,
                  }}
                >
                  Description du bien
                </Typography>
              </Grid>
              <Grid sx={{ marginTop: "1em" }} item md={2} xs={12}>
                <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
                  Valeur vénales*
                </Typography>
              </Grid>
              <Grid
                sx={{ marginTop: "1em" }}
                item
                md={4}
                xs={11}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <NumericFormat
                  thousandSeparator=" "
                  customInput={TextField}
                  allowNegative={false}
                  decimalScale={2}
                  allowedDecimalSeparators={["."]}
                  size="small"
                  fullWidth
                  id="valeurVenale"
                  type="number"
                  label="Valeur Vénales"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  value={
                    currentMandat?.bien?.valeurVenale
                      ? currentMandat.bien.valeurVenale
                      : ""
                  }
                  {...register("valeurVenale", {
                    onChange: (e) => {
                      setCurrentMandat({
                        ...currentMandat,
                        bien: {
                          ...currentMandat.bien,
                          valeurVenale: e.target.value,
                        },
                      });
                    },
                    onBlur: (e) => {
                      let m = editInstantRequest(
                        parseFloat(e.target.value),
                        currentMandat.bien["@id"],
                        "valeurVenale"
                      );

                      refresh();
                    },
                    validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                    setValueAs: (value) => {
                      return _.isEmpty(value) ? "" : parseFloat(value);
                    },
                  })}
                />
                {errors.valeurVenale && (
                  <Typography sx={_STYLES.errorMessage}>
                    Cette valeur doit être une donné numérique
                  </Typography>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Ventilation du prix
            </Typography>
          </Grid>

          {/* LISTE DES VENTILATIONS  */}
          {currentMandat?.typeVente?.nom !== "Viager Mixte" && (
            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                fontWeight={"semibold"}
                sx={{
                  color: _COLORS.primary,
                }}
              >
                {currentMandat?.ventilations?.length > 0
                  ? currentMandat?.typeVente?.nom
                  : "Aucune ventilation"}
              </Typography>
            </Grid>
          )}
          {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom !== "Viager Mixte" &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return !_.isNull(row.comptant);
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                  ></Ventilation>
                );
              })}
          {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom !== "Viager Mixte" &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return !_.isNull(row.bouquet);
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                  ></Ventilation>
                );
              })}
          {/* FIN DES VENTILATIONS */}
          {/** BOUTON AJOUTER */}
          {(currentMandat?.ventilations?.length > 0 || !showRente) &&
          !mandatVenteComptant &&
          !mandatVenteNuePropriete &&
          !mandatVenteTermeLibre &&
          !mandatVenteMixte &&
          !mandatVenteTermeOccupee ? (
            <Grid container>
              <Grid item md={10} xs={10}></Grid>
              <Grid item md={2} xs={2} sx={{}}>
                <Button
                  size="small"
                  sx={{ top: -42, color: "black" }}
                  onClick={createVentilation}
                >
                  <AddCircleIcon sx={{ marginRight: 1 }} /> Ajouter une ligne
                </Button>
              </Grid>
            </Grid>
          ) : null}
          {/** FIN BOUTON AJOUTER */}

          {/* LISTE DES VENTILATIONS VIAGER MIXTE */}
          {currentMandat?.typeVente?.nom === "Viager Mixte" && (
            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                fontWeight={"semibold"}
                sx={{
                  color: _COLORS.primary,
                }}
              >
                Viager Occupé
              </Typography>
            </Grid>
          )}
          {/* Viager Mixte OCCUPE BOUQUET */}
          {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom === "Viager Mixte" &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return (
                  !_.isNull(row.bouquet) && !_.isNull(row.isVMViagerOccupe)
                );
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                    special={"mixteOccupe"}
                  ></Ventilation>
                );
              })}
          {/* Add Line */}
          <Grid container>
            <Grid item md={10} xs={10}></Grid>
            <Grid item md={2} xs={2} sx={{}}>
              <Button
                size="small"
                sx={{ top: -42, color: "black" }}
                onClick={(e) => createVentilation(e, "occupe")}
                disabled={
                  currentMandat?.ventilations?.filter((row, i) => {
                    return !_.isNull(row.isVMViagerOccupe);
                  }).length > 4
                    ? true
                    : false
                }
              >
                <AddCircleIcon sx={{ marginRight: 1 }} /> Ajouter une ligne
              </Button>
            </Grid>
          </Grid>
          {/* PAS SUR DETRE A SUPPRIMER */}
          {/* Viager Mixte OCCUPE COMPTANT */}
          {/* {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom === "Viager Mixte" &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return (
                  !_.isNull(row.comptant) && !_.isNull(row.isVMViagerOccupe)
                );
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i + "-occupecomptant"}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                  ></Ventilation>
                );
              })} */}
          {currentMandat?.typeVente?.nom === "Viager Mixte" && (
            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                fontWeight={"semibold"}
                sx={{
                  color: _COLORS.primary,
                }}
              >
                Viager Libre
              </Typography>
            </Grid>
          )}
          {/* Viager Mixte LIBRE BOUQUET */}
          {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom === "Viager Mixte" &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return !_.isNull(row.bouquet) && !_.isNull(row.isVMViagerLibre);
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                    special={"mixteLibre"}
                  ></Ventilation>
                );
              })}
          {/* Add Line */}
          {currentMandat?.typeVente?.nom === "Viager Mixte" && (
            <Grid container>
              <Grid item md={10} xs={10}></Grid>
              <Grid item md={2} xs={2} sx={{}}>
                <Button
                  size="small"
                  sx={{ top: -42, color: "black" }}
                  onClick={(e) => createVentilation(e, "libre")}
                  disabled={
                    currentMandat?.ventilations?.filter((row, i) => {
                      return !_.isNull(row.isVMViagerLibre);
                    }).length > 4
                      ? true
                      : false
                  }
                >
                  <AddCircleIcon sx={{ marginRight: 1 }} /> Ajouter une ligne
                </Button>
              </Grid>
            </Grid>
          )}
          {/* PAS SUR DE GARDER */}
          {/* {currentMandat?.ventilations?.length > 0 &&
            currentMandat?.typeVente?.nom === "Viager Mixte" &&
            currentMandat?.ventilations
              .filter((row, i) => {
                return (
                  !_.isNull(row.mensualite) && !_.isNull(row.isVMViagerLibre)
                );
              })
              .map((row, i) => {
                return (
                  <Ventilation
                    row={row}
                    i={i + "-libre"}
                    register={register}
                    currentMandat={currentMandat}
                    setCurrentMandat={setCurrentMandat}
                    refresh={refresh}
                    requestHelper={requestHelper}
                  ></Ventilation>
                );
              })} */}

          {/* FIN DES VENTILATIONS */}

          {/** INDICE ET AUGMENTATION */}
          {(showRente || showAugmentation) && (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Grid
                  item
                  md={7}
                  xs={12}
                  display={"flex"}
                  flexDirection={"column"}
                  padding={1}
                  gap={2}
                  justifyContent={"center"}
                  sx={{
                    border: "2px solid rgb(238, 238, 238)",
                    borderRadius: "10px",
                    borderColor: "#d4bdc8",
                    width: "100%",
                  }}
                >
                  <Box display={"flex"}>
                    <Grid
                      item
                      md={8}
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Typography>
                        {!showRente
                          ? "Augmentation de la mensualité en cas de départ anticipé"
                          : "Augmentation de la rente en cas de départ anticipé"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      display={"flex"}
                      fllexDirection={"column"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <TextField
                        size="small"
                        id="renteAugmentation"
                        type="number"
                        label={
                          showRente
                            ? "Augmentation Rente Viagère"
                            : "Augmentation Mensualité"
                        }
                        value={
                          currentMandat?.renteAugmentation
                            ? currentMandat?.renteAugmentation
                            : ""
                        }
                        {...register("renteAugmentation", {
                          max: 100,
                          onChange: (e) => {
                            setCurrentMandat({
                              ...currentMandat,
                              renteAugmentation: e.target.value,
                            });
                          },
                          onBlur: (e) => {
                            let m = editInstantRequest(
                              parseFloat(e.target.value),
                              currentMandat["@id"],
                              "renteAugmentation"
                            );

                            m.then((m) => {
                              if (m["@context"] === "/api/contexts/Error") {
                                refresh();
                              } else {
                                setCurrentMandat(m);
                              }
                            });
                          },
                          // validate: (value) => !_.isNaN(value) || _.isEmpty(value),

                          // min: {
                          //   value: 0,
                          //   message: "Le pourcentage ne peut pas être négatif",
                          // },
                          // setValueAs: (value) => {
                          //   return _.isEmpty(value) ? "" : parseFloat(value);
                          // },
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                      {errors.renteAugmentation && (
                        <Typography color={"red"}>
                          {errors.renteAugmentation.message}
                        </Typography>
                      )}
                    </Grid>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography fontWeight={"bold"}>OU</Typography>
                  </Grid>
                  <Box display={"flex"}>
                    <Grid
                      item
                      md={8}
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Typography>Montant mensuel</Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      display={"flex"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <TextField
                        size="small"
                        id="montantAugmentation"
                        label="Montant Mensuel"
                        type="number"
                        value={
                          currentMandat?.montantAugmentation
                            ? currentMandat?.montantAugmentation
                            : ""
                        }
                        {...register("montantAugmentation", {
                          onChange: (e) => {
                            setCurrentMandat({
                              ...currentMandat,
                              montantAugmentation: e.target.value,
                            });
                          },
                          onBlur: (e) => {
                            let m = editInstantRequest(
                              parseFloat(e.target.value),
                              currentMandat["@id"],
                              "montantAugmentation"
                            );

                            m.then((m) => {
                              if (m["@context"] === "/api/contexts/Error") {
                                refresh();
                              } else {
                                setCurrentMandat(m);
                              }
                            });
                          },
                          // validate: (value) => !_.isNaN(value) || _.isEmpty(value),

                          // min: {
                          //   value: 0,
                          //   message: "Le pourcentage ne peut pas être négatif",
                          // },
                          // setValueAs: (value) => {
                          //   return _.isEmpty(value) ? "" : parseFloat(value);
                          // },
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                width={"100%"}
                marginTop={2}
              >
                <Grid item md={2} xs={12} marginTop={2}>
                  <Typography>Indexation annuelle de la rente*</Typography>
                </Grid>
                <Grid item md={7} xs={12} marginTop={2}>
                  <FormControl fullWidth size="small">
                    <Select
                      value={
                        currentMandat?.indice
                          ? currentMandat?.indice["@id"]
                          : ""
                      }
                      onChange={(e) => {
                        let m = editInstantRequest(
                          e.target.value,
                          currentMandat["@id"],
                          "indice"
                        );

                        m.then((m) => {
                          if (m["@context"] === "/api/contexts/Error") {
                            refresh();
                          } else {
                            setCurrentMandat(m);
                          }
                        });
                      }}
                    >
                      {indices.map((indice, i) => (
                        <MenuItem key={i} value={indice["@id"]}>
                          {indice.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
            </>
          )}
          {/** FIN INDICE ET AUGMENTATION */}
        </Grid>
      </Box>
    </>
  );
}

export default VentilationPrix;
