import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _COLORS, _TYPEMANDAT } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import _ from "lodash";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { editInstantRequest } from "../../../utils/formHelper";
import { createToast } from "../../../utils/toast";
import { arrayMensualite } from "./VentilationPrix";

function Ventilation({
  row,
  i,
  currentMandat,
  setCurrentMandat,
  refresh,
  register,
  requestHelper,
  special = "",
}) {
  const showMensualite =
    currentMandat?.typeVente?.nom &&
    arrayMensualite.includes(currentMandat.typeVente.nom);
  const comptantMode = !_.isNull(row.comptant);
  const label = comptantMode
    ? "Comptant"
    : !showMensualite
    ? "Bouquet"
    : "Mensualités";
  const [indicator, setIndicator] = useState(1);

  /**
   * Pour la gestion des changements dans les ventilations mets à jour le currentMandat
   * @param {*} value
   * @param {*} f
   * @param {*} ventilation
   */
  const handleChange = (value, f, ventilation) => {
    setCurrentMandat({
      ...currentMandat,
      ventilations: currentMandat.ventilations.map((v) => {
        if (v["@id"] === ventilation["@id"]) {
          v[f] = value;
        }
        return v;
      }),
    });
  };

  /**
   * Suppression d'une ventilation
   * @param {*} e
   * @param {*} row
   */
  const deleteVentilation = (e, row) => {
    requestHelper.doRequest("DELETE", row["@id"]).then((res) => {
      if (res.status === 204) {
        createToast("success", "Ventilation supprimée avec succès");
        setCurrentMandat({
          ...currentMandat,
          ventilations: currentMandat.ventilations.filter(
            (v) => v["@id"] !== row["@id"]
          ),
        });
      }
    });
  };

  const formatInputName = (i, type, special = "") => {
    return i + type + special;
  };

  useEffect(() => {
    if (currentMandat.typeVente.nom === "Viager Mixte") {
      setIndicator(2);
    }
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        width={"100%"}
        sx={{ padding: "0.5em" }}
      >
        <Grid item md={5} xs={12} display={"flex"} alignItems={"center"}>
          {/** Checkbox */}
          {currentMandat?.typeVente?.nom !== "Vente à terme libre" &&
            currentMandat?.typeVente?.nom !== "Viager Mixte" && (
              <FormGroup>
                <FormControlLabel
                  sx={_STYLES.typographyLabelTitle}
                  control={
                    <Checkbox
                      name={i + "selected" + special}
                      checked={row.selected}
                      onChange={(e) => {
                        let m = editInstantRequest(
                          e.target.checked,
                          row["@id"],
                          "selected"
                        );

                        refresh();
                      }}
                    />
                  }
                  label={label + " Net Vendeur "}
                />
              </FormGroup>
            )}
          {/* pour les mensualités  */}
          {showMensualite && !comptantMode && (
            <TextField
              size="small"
              label="Mensualités"
              type="number"
              id={row["@id"] + "-mensualite"}
              name={i + "mensualite"}
              value={row.mensualite ? row.mensualite : ""}
              {...register(i + "mensualite", {
                onChange: (e) => {
                  handleChange(e.target.value, "mensualite", row);
                },
                onBlur: (e) => {
                  let m = editInstantRequest(
                    parseFloat(e.target.value),
                    row["@id"],
                    "mensualite"
                  );

                  refresh();
                },
                validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                setValueAs: (value) => {
                  return _.isEmpty(value) ? "" : parseFloat(value);
                },
              })}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          )}

          {/* pour le bouquet  et */}
          {!showMensualite && !comptantMode && (
            <TextField
              size="small"
              label="Bouquet"
              type="number"
              id={formatInputName(i, "bouquet", special)}
              value={!_.isNull(row.bouquet) ? row.bouquet : ""}
              {...register(i + "bouquet" + special, {
                onChange: (e) => {
                  handleChange(e.target.value, "bouquet", row);
                },
                onBlur: (e) => {
                  let m = editInstantRequest(
                    parseFloat(e.target.value),
                    row["@id"],
                    "bouquet"
                  );

                  refresh();
                },
                validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                setValueAs: (value) => {
                  return _.isEmpty(value) ? "" : parseFloat(value);
                },
              })}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          )}
          {/* pour la vente au comptant  et */}
          {comptantMode && (
            <TextField
              size="small"
              label="Comptant"
              type="number"
              id={row["@id"] + "comptant"}
              name={i + "comptant"}
              value={!_.isNull(row.comptant) ? row.comptant : ""}
              {...register(i + "comptant", {
                onChange: (e) => {
                  // @TODO Update ventilation state
                  handleChange(e.target.value, "comptant", row);
                },
                onBlur: (e) => {
                  // @TODO Update ventilation server side
                  let m = editInstantRequest(
                    parseFloat(e.target.value),
                    row["@id"],
                    "comptant"
                  );

                  refresh();
                },
                validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                setValueAs: (value) => {
                  return _.isEmpty(value) ? "" : parseFloat(value);
                },
              })}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          )}
        </Grid>
        <Grid item md={0.5}></Grid>
        <Grid
          item
          md={5}
          xs={12}
          display={"flex"}
          alignItems={"center"}
          gap={1}
        >
          {/** Pour la rente viagére */}
          {!showMensualite && !comptantMode && (
            <>
              <TextField
                size="small"
                label="Rente Viagère"
                id={i + "renteViagere" + special}
                type="number"
                value={!_.isNull(row.renteViagere) ? row.renteViagere : ""}
                {...register(i + "renteViagere" + special, {
                  onChange: (e) => {
                    // @TODO Update ventilation state
                    handleChange(e.target.value, "renteViagere", row);
                  },
                  onBlur: (e) => {
                    // @TODO Update ventilation server side
                    let m = editInstantRequest(
                      parseFloat(e.target.value),
                      row["@id"],
                      "renteViagere"
                    );

                    refresh();
                  },
                  validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                  setValueAs: (value) => {
                    return _.isEmpty(value) ? "" : parseFloat(value);
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
              {i > indicator ? (
                <IconButton
                  sx={{ color: "black" }}
                  onClick={(e) => {
                    //  @TODO Delete ventilation
                    deleteVentilation(e, row);
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              ) : null}
            </>
          )}
          {/** Pour la durée */}
          {showMensualite && !comptantMode && (
            <>
              <TextField
                size="small"
                label="Durée"
                name={i + "duree"}
                type="number"
                id={row["@id"] + "duree"}
                value={row.duree ? row.duree : ""}
                {...register(i + "duree", {
                  onChange: (e) => {
                    // @TODO Update ventilation state
                    handleChange(e.target.value, "duree", row);
                  },
                  onBlur: (e) => {
                    // @TODO Update ventilation server side
                    let m = editInstantRequest(
                      parseFloat(e.target.value),
                      row["@id"],
                      "duree"
                    );

                    refresh();
                  },
                  validate: (value) => !_.isNaN(value) || _.isEmpty(value),
                  setValueAs: (value) => {
                    return _.isEmpty(value) ? "" : parseFloat(value);
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {currentMandat?.typeVente?.nom !==
                        "Vente à terme libre" &&
                      currentMandat?.typeVente?.nom !== "Vente à terme occupée"
                        ? "mois"
                        : "ans"}
                    </InputAdornment>
                  ),
                }}
              />
              {label === "Mensualités" ? (
                <IconButton
                  sx={{ color: "black" }}
                  onClick={(e) => {
                    deleteVentilation(e, row);
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              ) : null}
              {i > 1 ? (
                <IconButton
                  sx={{ color: "black" }}
                  onClick={(e) => {
                    deleteVentilation(e, row);
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              ) : null}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default Ventilation;
