import { _COLORS } from "./_constants";

/**
 * Permet de créer un bouton
 * A ajouter directement dans sx={btn(...)} si on ajoute pas de propriété supplémentaire
 * Sinon faire sx={{...btn(...), ...{propriété: valeur, propriété: valeur}}}
 *
 * @param color Couleur du button
 * @param hover Couleur du button au survol
 * @param width Largeur du button en %
 * @returns
 */
export function btn(color, hover, width = "90%") {
  return {
    fontWeight: "bold",
    width: width,
    whiteSpace: "nowrap",
    backgroundColor: color,
    color: "white",
    borderColor: color,
    "&:hover": {
      backgroundColor: hover,
      borderColor: hover,
    },
  };
}

export const _STYLES = {
  /** TEXTS */
  errorMessage: {
    color: _COLORS.danger,
    fontStyle: "italic",
    fontSize: "0.8rem",
  },
  typographyLabelTitle: {
    fontWeight: "bold",
    fontSize: "1.0rem",
  },
  /** INPUTS */
  basicInput: {
    width: "100%",
    margin: "0.5em",
  },
  /** BOXES */
  mainContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  filterFormGrid: {
    padding: "1em",
    display: "flex",
    width: "100%",
    // height: "20%",
    border: "3px solid RGB(238, 238, 238)",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  secondaryContainer: {
    padding: "1em",
    display: "flex",
    width: "100%",
    height: "75%",
    justifyContent: "flex-start",
    border: "3px solid RGB(238, 238, 238)",
    borderRadius: "10px",
  },
  dataGridContainer: {
    width: "95%",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  boxInputRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  boxSubmitModal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "1em",
  },
  dashboardItem: {
    borderRadius: "10px",
    textAlign: "center",
    color: _COLORS.white,
    height: "90%",
    width: "80%",
    padding: "10px",
    cursor: "pointer",
  },
  sxGridChild: {
    width: "100%",
    border: "2px solid rgb(238, 238, 238)",
    borderRadius: "10px",
    marginTop: 2,
  },
};
