import _ from "lodash";
import { _ACTIONS, _FORMATS, _SWAL_ICONS } from "./_constants";
import { createToast } from "./toast";
import { RequestHelper } from "./requestHelper";

/**
 * Créer des options pour un select a partir d'une réponse API
 *
 * @param {Object} response
 * @returns
 */
export function buildOptionsFromResponse(
  response,
  valueKey = "id",
  labelKey = "name"
) {
  if (!response) return [];

  return response["hydra:member"].map((item) => ({
    value: item[valueKey],
    label: item[labelKey],
  }));
}

/**
 *
 * @param {Object} formData
 *
 * @returns {Object}
 *
 */
export function getQueryParamsFromFormData(formData, mode) {
  const params = {};
  //Boucler sur l'objet formData
  //Si la valeur est vide ou null, on ne l'ajoute pas
  //Sinon on l'ajoute dans params
  for (let key in formData) {
  
    let value = formData[key];
    if (mode === "edit" && key.includes(".")) {
      continue;
    }
    if (
      (key.includes("date") || key.includes("At")) &&
      value !== "" &&
      value !== null
    ) {
      key = key.includes("AF")
        ? key.replace("AF", "[after]")
        : key.replace("BF", "[before]");
      params[key] = value;
      continue;
    }
    if (value !== "" && value !== null) {
      params[key] = value;
    }
  }

  return params;
}

/**
 * Envoie un requete directement au onChange d'un select pour modifier une valeur en BDD
 *
 * @param {*} value
 * @param {*} field
 * @param {*} url
 */
export async function editInstantRequest(value, url, field) {
  const requestHelper = new RequestHelper();
  const arrayFields = ["bouquet", "renteViagere", "comptant"];
  if (arrayFields.includes(field)) {
    value =  isNaN(value) ? 0 : value
  }
  return await requestHelper
    .doRequest(_ACTIONS.PUT, url, { [field]: value})
    .then((response) => {
      if (response.status == 200) {
        // Gardert le toast pour plus tard peut etre
        // createToast(
        //   _SWAL_ICONS.success,
        //   "La modification du champ a été effectuée avec succès"
        // );

        return response.data;
      } else {
        createToast(
          _SWAL_ICONS.error,
          "Une erreur est survenue lors de la modification"
        );
        return response.data;
      }
    });
}
