import React, { useEffect, useState } from "react";
import { RequestHelper } from "../../utils/requestHelper";
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  _ACTIONS,
  _COLORS,
  _MESSAGES,
  _SWAL_ICONS,
} from "../../utils/_constants";
import Select from "@mui/material/Select";
import { useForm } from "react-hook-form";
import { createToast } from "../../utils/toast";
import { _STYLES } from "../../utils/_style_constants";
import { UserContext } from "../contexts/UserContext";
import { PatternFormat } from "react-number-format";

const UserForm = ({
  setShowModal,
  edit = null,
  agences,
  rowsProfils,
  refresh,
  setUsers,
  setRefresh,
}) => {
  /* Valeurs inputs du Formulaire  */
  const [selectedAgences, setSelectedAgences] = useState([]);
  const [selectedProfil, setSelectedProfil] = useState("");
  const [selectedCivilite, setSelectedCivilite] = useState("");
  const [represent, setRepresent] = useState(false);
  const { token } = React.useContext(UserContext);
  const { user } = React.useContext(UserContext);
  const requestHelper = new RequestHelper(token);

  /* Récupère les variable React Hook Form */
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nom: edit ? edit.nom : "",
      prenom: edit ? edit.prenom : "",
      agences: edit ? edit.agences : "",
      email: edit ? edit.email : "",
      profil: edit ? edit?.profil["@id"] : "",
      represent: edit ? edit.represent : "",
      portable: edit ? edit.portable : "",
      civilite: edit ? edit.civilite : "",
    },
  });

  const extractAgenceIds = (agences) => {
    return agences.map((agence) => agence["@id"]);
  };

  useEffect(() => {
    if (!edit) {
      reset();
      setUsers(null);
    } else {
      setSelectedProfil(edit.profil["@id"]);
      setValue("profil", edit.profil["@id"]);
      // Extraire les IDs des agences depuis edit.agences et mettre à jour l'état
      const agenceIds = extractAgenceIds(edit.agences);
      setSelectedAgences(agenceIds);
      setSelectedCivilite(edit.civilite);
      setRepresent(edit.represent);
    }
  }, [edit]);

  /* Méthode pour mettre à jour les données du formulaire */
  const handleChangeAgence = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAgences(typeof value == [] ? value.split(",") : value);
  };

  const handleChangeProfil = (event) => {
    setSelectedProfil(event.target.value);
  };

  const onSubmit = (data) => {
    const method = edit ? _ACTIONS.PUT : _ACTIONS.POST;
    data = {
      ...data,
      agences: selectedAgences,
      represent: represent ? true : false,
    };
    requestHelper
      .doRequest(method, edit ? "/api/users/" + edit.id : "/api/users", data)
      .then((response) => {
        setRefresh(refresh + 1);
        setShowModal(false);
        if (response.data["@id"]) {
          createToast(
            _SWAL_ICONS.success,
            edit ? _MESSAGES.users_edit_success : _MESSAGES.users_create_success
          );
        } else {
          createToast(
            _SWAL_ICONS.error,
            edit ? _MESSAGES.users_edit_error : _MESSAGES.users_create_error
          );
        }
      });
  };

  const civilites = [
    { id: "", nom: "Non renseigné" },
    { id: "/api/civilites/1", nom: "Monsieur" },
    { id: "/api/civilites/2", nom: "Madame" },
  ];

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel id="civiliteLabel">Civilité</InputLabel>
              <Select
                size="small"
                sx={_STYLES.basicInput}
                value={selectedCivilite}
                {...register("civilite", { required: true })}
                onChange={(e) =>
                  setSelectedCivilite(e.target.value) &&
                  setValue("civilite", e.target.value)
                }
              >
                {civilites.map((civilite, i) => (
                  <MenuItem key={i} value={civilite.id}>
                    {civilite.nom}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                sx={_STYLES.basicInput}
                size="small"
                id="prenom"
                label="Prénom"
                {...register("prenom", { required: true })}
                helperText={
                  errors.prenom?.type === "required" && (
                    <Typography sx={_STYLES.errorMessage}>
                      Le prénom est obligatoire
                    </Typography>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                sx={_STYLES.basicInput}
                size="small"
                id="nom"
                label="Nom"
                {...register("nom", { required: true })}
                helperText={
                  errors.nom?.type === "required" && (
                    <Typography sx={_STYLES.errorMessage}>
                      Le nom est obligatoire
                    </Typography>
                  )
                }
              />
            </Grid>
          </Grid>
          <FormControl fullWidth>
            <InputLabel id="agencesLabel">Agences</InputLabel>
            <Select
              size="small"
              sx={_STYLES.basicInput}
              {...register("agences", { required: true })}
              multiple
              value={selectedAgences}
              onChange={handleChangeAgence}
            >
              {agences.map((agence, i) => (
                <MenuItem key={i} value={agence["@id"]}>
                  {agence.nom}
                </MenuItem>
              ))}
            </Select>
            {errors.agences && (
              <Typography sx={_STYLES.errorMessage}>
                L'agence est obligatoire
              </Typography>
            )}
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                sx={_STYLES.basicInput}
                size="small"
                label="Email"
                id="email"
                {...register("email", { required: true })}
                helperText={
                  errors.email?.type === "required" && (
                    <Typography sx={_STYLES.errorMessage}>
                      L'email est obligatoire
                    </Typography>
                  )
                }
              />
            </Grid>
            {user.profil !== "USER" && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={_STYLES.basicInput} size="small">
                  <InputLabel id="select-label">Profil</InputLabel>
                  <Select
                    id="profil"
                    {...register("profil", { required: true })}
                    value={selectedProfil}
                    onChange={handleChangeProfil}
                  >
                    {rowsProfils.map((profil, i) => (
                      <MenuItem key={i} value={profil["@id"]}>
                        {profil.nom}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.profil && (
                    <Typography sx={_STYLES.errorMessage}>
                      Le profil est obligatoire
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <PatternFormat
                format="##.##.##.##.##"
                customInput={TextField}
                fullWidth
                sx={_STYLES.basicInput}
                size="small"
                label="Portable"
                id="portable"
                {...register("portable", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: /^[0-9]*$/,
                })}
                helperText={
                  <>
                    {errors.portable?.type === "required" && (
                      <Typography sx={_STYLES.errorMessage}>
                        Le portable est obligatoire
                      </Typography>
                    )}
                    {errors.portable?.type === "minLength" && (
                      <Typography sx={_STYLES.errorMessage}>
                        Le portable doit contenir 10 chiffres
                      </Typography>
                    )}
                    {errors.portable?.type === "maxLength" && (
                      <Typography sx={_STYLES.errorMessage}>
                        Le portable doit contenir 10 chiffres
                      </Typography>
                    )}
                    {errors.portable?.type === "pattern" && (
                      <Typography sx={_STYLES.errorMessage}>
                        Le portable doit contenir 10 chiffres
                      </Typography>
                    )}
                  </>
                }
              ></PatternFormat>
            </Grid>
            {user.profil !== "USER" && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={represent}
                      onChange={(e) => setRepresent(e.target.checked)}
                    />
                  }
                  sx={_STYLES.basicInput}
                  size="small"
                  name="represent"
                  label="Autoriser à représenter l'agence"
                />
              </FormGroup>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{
                mt: 2,
                backgroundColor: _COLORS.light,
                borderColor: _COLORS.light,
                "&:hover": {
                  backgroundColor: _COLORS.light,
                  borderColor: _COLORS.light,
                },
              }}
              onClick={() => setShowModal(false)}
              variant="contained"
              type="button"
            >
              Annuler
            </Button>
            <Button
              sx={{
                mt: 2,
                backgroundColor: _COLORS.primary,
                borderColor: _COLORS.primary,
                "&:hover": {
                  backgroundColor: _COLORS.light,
                  borderColor: _COLORS.light,
                },
              }}
              variant="contained"
              type="submit"
            >
              {edit ? "Modifier" : "Créer"}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default UserForm;
