import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { _ACTIONS, _COLORS, _FORMATS } from "../../utils/_constants";
import { btn } from "../../utils/_style_constants";
import { getQueryParamsFromFormData } from "../../utils/formHelper";
import { RequestHelper } from "../../utils/requestHelper";

/**
 * @desc The periodeOpt array contains the options for the periode select.
 * Each option has a value and a text to display.
 * @type {Array}
 */
export const periodeOpt = [
  { value: "", text: "Aucune période" },
  { value: "currentYear", text: "Cette année" },
  { value: "lastYear", text: "L'année dernière" },
  { value: "currentMonth", text: "Ce mois-ci" },
  { value: "lastMonth", text: "Le mois dernier" },
  { value: "last3Month", text: "Les 3 derniers mois" },
  { value: "last6Month", text: "Les 6 derniers mois" },
];

export function SignRequestFilterForm(props) {
  const { control, handleSubmit, register, setValue } = useForm();
  const [searchNumeroRequest, setSearchNumeroRequest] = useState("");
  const [statutSelected, setStatutSelected] = useState("");
  const [periodeSelected, setPeriodeSelected] = useState("");
  const [dateDebValue, setDateDebValue] = useState(null);
  const [dateFinValue, setDateFinValue] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const formRef = React.useRef(null);
  const requestHelper = new RequestHelper();

  const statuts = [
    { value: 0, label: "En attente" },
    { value: 3, label: "En Cours" },
    { value: 4, label: "Terminé" },
    { value: 5, label: "Requète annulée par un signataire" },
    { value: 6, label: "Requète annulée par l'application" },
    { value: 7, label: "Requète expirée" },
    { value: 8, label: "Requète en erreur" },
  ];

  const handleDateDebChange = (date) => {
    setDateDebValue(date);
    setValue("createdAtAF", date.format(_FORMATS.dateTime));
  };

  const handleDateFinChange = (date) => {
    setDateFinValue(date);
    setValue("createdAtBF", date.format(_FORMATS.dateTime));
  };

  /**
   * Handles the change of the periode select.
   *
   * @param {*} e
   */
  const handlePeriodeChange = (e) => {
    setPeriodeSelected(e.target.value);
    setDisabled(true);
    let debVal;
    let finVal;
    switch (e.target.value) {
      case "currentYear":
        debVal = moment().startOf("year");
        finVal = moment().endOf("year");
        break;
      case "lastYear":
        debVal = moment().subtract(1, "year").startOf("year");
        finVal = moment().subtract(1, "year").endOf("year");
        break;
      case "currentMonth":
        debVal = moment().startOf("month");
        finVal = moment().endOf("month");
        break;
      case "lastMonth":
        debVal = moment().subtract(1, "month").startOf("month");
        finVal = moment().subtract(1, "month").endOf("month");
        break;
      case "last3Month":
        debVal = moment().subtract(3, "month").startOf("month");
        finVal = moment().endOf("month");
        break;
      case "last6Month":
        debVal = moment().subtract(6, "month").startOf("month");
        finVal = moment().endOf("month");
        break;
      default:
        setDisabled(false);
    }
    if (e.target.value === "") {
      debVal = null;
      finVal = null;
      setDateDebValue(debVal);
      setDateFinValue(finVal);
      setValue("createdAtAF", "");
      setValue("createdAtBF", "");

      return;
    }
    setDateDebValue(debVal);
    setDateFinValue(finVal);
    setValue("createdAtAF", debVal.format(_FORMATS.dateTime));
    setValue("createdAtBF", finVal.format(_FORMATS.dateTime));
  };

  const onSearch = (formData) => {
    const params = getQueryParamsFromFormData(formData);

    requestHelper
      .doRequest(_ACTIONS.GET, "/api/sign_requests", params)
      .then((response) => {
        if (response.status === 200)
          props.setRowsSignRequest(response.data["hydra:member"]);
      });
  };

  useEffect(() => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }, [props.refresh]);

  return (
    <>
      <form
        ref={formRef}
        action=""
        onSubmit={handleSubmit(onSearch)}
        style={{ width: "100%" }}
      >
        <Grid rowSpacing={1} container>
          <Grid item xl={3}>
            <TextField
              sx={{ width: "90%" }}
              size="small"
              value={searchNumeroRequest}
              onChange={(e) => {
                setSearchNumeroRequest(e.target.value);
              }}
              label="N° Requête"
              {...register("requestId", { required: false })}
              name="requestId"
            ></TextField>
          </Grid>
          <Grid item xl={3}>
            <FormControl sx={{ width: "90%" }}>
              <InputLabel size="small">Statut</InputLabel>
              <Select
                label="requestStatus"
                size="small"
                {...register("requestStatus", { required: false })}
                id="requestStatus"
                value={statutSelected}
                onChange={(e) => {
                  setStatutSelected(e.target.value);
                }}
                input={<OutlinedInput label="Name" />}
              >
                <MenuItem value="">Tous</MenuItem>
                {statuts.map((statut, i) => (
                  <MenuItem key={i} value={statut.value}>
                    {statut.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xl={3}></Grid>
          <Grid item xl={3}></Grid>
          <Grid item xl={3}>
            <FormControl sx={{ width: "90%" }}>
              <InputLabel size="small">Période</InputLabel>
              <Select
                label="Période"
                size="small"
                id="periode"
                value={periodeSelected}
                input={<OutlinedInput label="Name" />}
                onChange={handlePeriodeChange}
                MenuProps={""}
              >
                {periodeOpt.map((periode, i) => (
                  <MenuItem key={i} value={periode.value}>
                    {periode.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xl={3}>
            <Controller
              name="createdAtAF"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <DatePicker
                  sx={{ width: "90%" }}
                  label="Date de début"
                  size="small"
                  format="DD/MM/YYYY"
                  value={dateDebValue}
                  disabled={disabled}
                  onChange={handleDateDebChange}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xl={3}>
            <Controller
              name="createdAtBF"
              control={control}
              defaultValue={null}
              value={dateFinValue}
              render={({ field }) => (
                <DatePicker
                  sx={{ width: "90%" }}
                  label="Date de Fin"
                  size="small"
                  format="DD/MM/YYYY"
                  value={dateFinValue}
                  disabled={disabled}
                  onChange={handleDateFinChange}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xl={1}></Grid>
          <Grid item xl={2}>
            <Button
              sx={{
                ...btn(_COLORS.primary, _COLORS.primaryLight),
                width: "85%",
              }}
              variant="Rechercher"
              type="submit"
            >
              Rechercher
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
