import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { _COLORS } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { PatternFormat, NumericFormat } from "react-number-format";

function RemunerationMandataire({ currentMandat, setCurrentMandat, refresh }) {
  return (
    <>
      <Grid container spacing={1} padding={4}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Rémunération du mandataire
          </Typography>
        </Grid>
        <Grid item xs={3} md={2} alignItems={"center"} display={"flex"}>
          <Typography>Rémunération TTC*</Typography>
        </Grid>
        <Grid item xs={9} md={3} display={"flex"} gap={1} alignItems={"center"}>
          <NumericFormat
            thousandSeparator=" "
            customInput={TextField}
            allowNegative={false}
            decimalScale={2}
            allowedDecimalSeparators={["."]}
            id="remuneration"
            size="small"
            fullWidth
            disabled={currentMandat?.type === "avenant"}
            value={
              currentMandat?.remuneration
                ? Number(currentMandat?.remuneration)
                : ""
            }
            onChange={(e) => {
              setCurrentMandat({
                ...currentMandat,
                remuneration: e.target.value,
              });
            }}
            onBlur={(e) => {
              let m = editInstantRequest(
                parseFloat(e.target.value.replace(" ", "")),
                currentMandat["@id"],
                "remuneration"
              );

              m.then((m) => {
                if (m["@context"] === "/api/contexts/Error") {
                  refresh();
                } else {
                  setCurrentMandat(m);
                }
              });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">€ TTC</InputAdornment>
              ),
            }}
          />
        </Grid>
        {currentMandat.type != "recherche" && (
          <>
            <Grid item xs={12} md={2} />
            <Grid item xs={3} md={2} alignItems={"center"} display={"flex"}>
              <Typography>A la charge*</Typography>
            </Grid>
            <Grid item xs={9} md={3}>
              <FormControl fullWidth>
                <InputLabel size="small"> A la charge de</InputLabel>
                <Select
                  label="A la charge de"
                  size="small"
                  disabled={currentMandat?.type === "avenant"}
                  value={
                    currentMandat?.remunerationPayeur
                      ? currentMandat?.remunerationPayeur
                      : ""
                  }
                  id="remunerationPayeur"
                  onChange={(e) => {
                    let m = editInstantRequest(
                      e.target.value,
                      currentMandat["@id"],
                      "remunerationPayeur"
                    );

                    m.then((m) => {
                      if (m["@context"] === "/api/contexts/Error") {
                        refresh();
                      } else {
                        setCurrentMandat(m);
                      }
                    });
                  }}
                >
                  <MenuItem value="vendeur"> Du vendeur</MenuItem>
                  <MenuItem value="acquereur"> De l'acquéreur</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default RemunerationMandataire;
